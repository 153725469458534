import React, { Component } from 'react';
import { connect } from 'react-redux';
import cn from 'classnames';
import { AddRowButton } from './AddRowButton';
import { HeaderItem } from './HeaderItem';

class Header extends Component {
    shouldComponentUpdate = nextProps => {
        return this.props.orderBy !== nextProps.orderBy ||
            this.props.headers !== nextProps.headers ||
            this.props.position == null ||
            nextProps.position == null ||
            (this.props.position != null &&
            nextProps.position != null &&
            this.props.position.columnName !== nextProps.position.columnName)
    }

    render = () => {
        if (!this.props.headers) return null;

        const { addRowVisible = true } = this.props;

        return (
            <thead>
                <tr>
                    <th scope="col" className="cell-no-data">
                        {
                            addRowVisible &&
                            <div className={cn('action-wrap', { 'cell-action': addRowVisible })}>
                                <div className="action-box">
                                    <AddRowButton index={-1} />
                                </div>
                            </div>
                        }
                    </th>
                    <th scope="col" className={cn('cell-no-data cell-number', { 'cell-action': addRowVisible })}></th>
                    {
                        this.props.headers.map(h =>
                            <HeaderItem
                                key={h.name + h.title}
                                markOptional={this.props.markOptionalFields}
                                column={h}
                                orderBy={this.props.orderBy}
                                position={this.props.position}
                            />
                        )
                    }
                    <th scope="col" className="cell-no-data"></th>
                </tr>
            </thead>);
    }
}

const mapStateToProps = ({ grid }) => ({
    headers: grid.headers,
    orderBy: grid.orderBy,
    position: grid.position
});

const connectedHeader = connect(mapStateToProps)(Header);
export { connectedHeader as Header };
