import React from "react";
import { connect } from 'react-redux';
import { formsActions as actions } from '../../actions';

class Form extends React.Component {
    componentDidMount = () => this.props.dispatch(actions.reset())

    handleSubmit = event => {
        const { onSubmit, onCustomValidate, dispatch } = this.props;
        let isValid = event.target.checkValidity();

        if (isValid && onCustomValidate) {
            isValid = isValid && onCustomValidate();
        }

        dispatch(actions.activateValidation());

        if (isValid) {
            onSubmit(isValid);
        }

        event.preventDefault();
    };

    render = () => {
        return (
            <form id={this.props.id || 'inputForm'} className={this.props.className} noValidate onSubmit={this.handleSubmit}>
                {this.props.children}
            </form>
        );
    };
}

const mapStateToProps = () => ({});

const connectedForm = connect(mapStateToProps)(Form);
export { connectedForm as Form };
