import React, { Component } from 'react';
import { connect } from 'react-redux';
import cn from 'classnames';
import { contactsSaveActions, gridActions } from '../../actions';
import { Color, DateCell, Lookup, PxTalk, Select, Text, Textarea } from './Cells';
import { Lookup2 } from './Cells/Lookup2';
import { gridColumns as columns } from '../../constants';
import { AddRowButton } from './AddRowButton';
import { OnHoverTooltip } from '../common';
import IconSVG from '../../styles/svg-icons';
import { FileUpload } from './Cells/FileUpload';
import { DocumentStoreType } from '../../types/document/Document';

class Row extends Component {
    shouldComponentUpdate = nextProps =>
        this.props.index !== nextProps.index ||
        !!this.props.isFirstDraft !== !!nextProps.isFirstDraft ||
        (this.props.position && this.props.position.index === this.props.index) ||
        (nextProps.position && nextProps.position.index === nextProps.index) ||
        this.props.dataItem !== nextProps.dataItem ||
        this.props.selection.some(s => s === this.props.index) ||
        Boolean(this.showHelp) !== Boolean(nextProps.showHelp) ||
        this.props.headers?.length !== nextProps.headers?.length ||
        nextProps.selection.some(s => s === nextProps.index);

    render = () => {
        const {
            dataItem,
            position,
            index,
            selection,
            showHelp,
            headers,
            addRowVisible = true,
            moveRowVisible = true,
            deleteRowVisible = true,
            readonlyTooltip,
            deleteDisabled,
            dispatch
        } = this.props;

        let rowError = false;

        const cells = headers.map((c, columnIndex) => {
            const hasFocus =
                position &&
                position.index === index &&
                position.columnName === c.name;

            const editing = hasFocus && position.editing;

            let value = dataItem[c.name];
            let error = dataItem.errors && dataItem.errors.find(e => e.columnName === c.name);

            if (editing) {
                value = position.editingValue;
                error = { message: position.editingError };
            }

            const cellError = error && error.message;
            if (cellError) {
                rowError = true;
            }

            const helpVisible = showHelp && columnIndex === 0 && index === 0 && dataItem.draft;

            return this.createCell(
                c,
                hasFocus,
                editing,
                value,
                cellError,
                helpVisible,
                helpVisible,
                c.readonly || dataItem.readonly,
                dataItem
            );
        });

        const selected = cn({
            selected: selection.some(s => s === index),
            readonly: dataItem.readonly
        });

        const highlight = cn('cell-item', {
            highlight: position && position.index === index
        });

        const newLabel = (dataItem.isNew && <span className="state-new">new</span>);
        const errorLabel = (rowError && <i className="icon icon-warning yellow cell-icon-warning" />);
        const updateLabel = (dataItem.isUpdate && <span className="state-updated">upd</span>);
        const flag = (dataItem.isFlagged && <IconSVG className="flagged" name="flag" width={16} height={16} />);

        return (
            <OnHoverTooltip
                disabled={!dataItem.readonly}
                overlay={readonlyTooltip}
            >
                <tr className={selected}>
                    <td className="cell-no-data">
                        <div className={cn({ 'action-wrap': true, 'cell-action': addRowVisible })}>
                            {deleteRowVisible &&
                                <button
                                    type="button"
                                    className="btn-link btn-danger"
                                    disabled={deleteDisabled}
                                    onClick={this.handleRowDelete.bind(this, dataItem)}
                                >
                                    <i className="icon icon-delete" />
                                </button>
                            }
                            {errorLabel || flag || newLabel || updateLabel}
                            {
                                moveRowVisible &&
                                <div className="action-move">
                                    <div className="action-move-col">
                                        <button
                                            onClick={() => dispatch(gridActions.moveRowUp(index))}
                                            className="btn-link btn-action-up"
                                        >
                                            <IconSVG name="arrow-up-big" width="16" height="16" />
                                        </button>
                                    </div>
                                    <div className="action-move-col">
                                        <button
                                            onClick={() => dispatch(gridActions.moveRowDown(index))}
                                            className="btn-link btn-action-down"
                                        >
                                            <IconSVG name="arrow-up-big" width="16" height="16" />
                                        </button>
                                    </div>
                                </div>
                            }
                            {
                                addRowVisible &&
                                <div className="action-box">
                                    <div className="action-top">
                                        <OnHoverTooltip overlay="Insert row">
                                            <button
                                                onClick={() => dispatch(gridActions.addRow(index))}
                                                className="btn-link btn-action-add"
                                            >
                                                <IconSVG name="plus-round" width="16" height="16" />
                                            </button>
                                        </OnHoverTooltip>
                                    </div>
                                    <AddRowButton index={index} />
                                </div>
                            }
                        </div>
                    </td>
                    <td className={cn('cell-no-data cell-number', { 'cell-action': moveRowVisible })} onClick={this.handleSelectRow}>
                        <div className={highlight}>
                            {index + 1}
                        </div>
                    </td>
                    {cells}
                    <td className="cell-no-data cell-no-data-end" />
                </tr>
            </OnHoverTooltip>
        );
    }

    createCell = (
        column,
        hasFocus,
        editing,
        value,
        error,
        showHelp,
        showPlaceholder,
        readonly,
        dataItem
    ) => {
        switch (column.name) {
            case columns.pxTalks.name:
                return (
                    <PxTalk
                        key={column.name}
                        value={value}
                        hasFocus={hasFocus}
                        editing={editing}
                        onClick={this.handleCellClick}
                    />
                )
            case columns.color.name:
                return (
                    <Color
                        key={column.name}
                        value={value}
                        hasFocus={hasFocus}
                        editing={editing}
                        onClick={this.handleCellClick}
                    />
                )
            case columns.company.name:
                return (
                    <Lookup
                        key={column.name}
                        columnName={column.name}
                        maxLength={column.length}
                        hasFocus={hasFocus}
                        editing={editing}
                        value={value}
                        error={error}
                        lookupDataItems={this.props.companiesLookup.companies.map(company => ({
                            company,
                            text: company.name
                        }))}
                        onClick={this.handleCellClick}
                        onLookupItemSelected={this.handleComapnyLookupItemSelected}
                        onSearchTermChange={this.handleCompanyLookupSearchTermChange}
                        onLookupReset={this.handleCompanyLookupReset}
                        helpPopoverTitle={this.props.helpPopoverTitle}
                        disableUppercase={column.disableUppercase}
                    />
                )
            default:
                return this.createCellByType(
                    column,
                    value,
                    hasFocus,
                    editing,
                    error,
                    showHelp,
                    showPlaceholder,
                    readonly,
                    dataItem
                );
        }
    }

    createCellByType = (
        column,
        value,
        hasFocus,
        editing,
        error,
        showHelp,
        showPlaceholder,
        readonly,
        dataItem
    ) => {
        const key = column.name + column.title;

        switch (column.type) {
            case 'longText':
                return (
                    <Textarea
                        key={key}
                        column={column}
                        hasFocus={hasFocus}
                        editing={editing}
                        value={value}
                        error={error}
                        readonly={readonly}
                        onClick={this.handleCellClick}
                    />
                );
            case 'select':
                return (
                    <Select
                        key={key}
                        format={column.format}
                        hasFocus={hasFocus}
                        readonly={readonly}
                        editing={editing}
                        value={value}
                        columnName={column.name}
                        onClick={this.handleCellClick}
                        source={column.selectSourceItemsCallback ? column.selectSourceItemsCallback() : []}
                        error={error}
                        keepEmptyOption={column.keepEmptyOption}
                    />
                );
            case 'lookup':
                return (
                    <Lookup2
                        key={key}
                        columnName={column.name}
                        maxLength={column.length}
                        hasFocus={hasFocus}
                        editing={editing}
                        value={value}
                        error={error}
                        showHelp={showHelp}
                        showPlaceholder={showPlaceholder}
                        placeholder="Add New"
                        helpPopoverTitle={this.props.helpPopoverTitle}
                        onClick={this.handleCellClick}
                        lookupDataSource={column.lookup}
                        disableUppercase={column.disableUppercase}
                    />
                );
            case 'file':
                return (
                    <FileUpload
                        key={key}
                        columnName={column.name}
                        hasFocus={hasFocus}
                        editing={editing}
                        value={value}
                        readonly={readonly}
                        dataItem={dataItem}
                        error={error}
                        readonlyCallback={column.readonlyCallback}
                        maxSize={column.file.maxSize}
                        acceptedTypes={column.file.acceptedTypes}
                        documentStoreType={DocumentStoreType.Disclosure}
                        onClick={this.handleCellClick}
                    />
                );
            case 'date':
                return (
                    <DateCell
                        key={key}
                        column={column}
                        value={value}
                        hasFocus={hasFocus}
                        editing={editing}
                        error={error}
                        readonly={column.readonly}
                        showHelp={showHelp}
                        showPlaceholder={showPlaceholder}
                        onClick={this.handleCellClick}
                    />
                );
            default:
                return (
                    <Text
                        key={key}
                        column={column}
                        value={value}
                        hasFocus={hasFocus}
                        editing={editing}
                        error={error}
                        onClick={this.handleCellClick}
                        showHelp={showHelp}
                        showPlaceholder={showPlaceholder}
                        placeholder="Add New"
                        readonly={readonly}
                        helpPopoverTitle={this.props.helpPopoverTitle}
                    />
                );
        }
    }

    handleComapnyLookupItemSelected = (companyLookupItem) =>
        this.props.dispatch(contactsSaveActions.selectCompany(companyLookupItem.company));

    handleCompanyLookupSearchTermChange = searchTerm =>
        this.props.dispatch(contactsSaveActions.filterCompanies(searchTerm));

    handleCompanyLookupReset = () =>
        this.props.dispatch(contactsSaveActions.resetCompaniesLookup());

    handleRowDelete = dataItem => {
        const { index, onRowDeleted, dispatch } = this.props;

        dispatch(gridActions.deleteRow(index));

        if (onRowDeleted && !dataItem.draft) {
            onRowDeleted(dataItem);
        }
    }

    handleCellClick = (columnName, hasFocus) => {
        if (hasFocus) {
            this.props.dispatch(gridActions.edit());
        } else {
            this.props.dispatch(gridActions.setFocus(this.props.index, columnName))
        }
    }

    handleSelectRow = e =>
        this.props.dispatch(gridActions.selectRow(this.props.index, e.ctrlKey, e.shiftKey));
}

const mapStateToProps = ({ grid, securities, contactsSave }) => ({
    companiesLookup: contactsSave.companiesLookup,
    selection: grid.selection,
    position: grid.position,
    showHelp: grid.showHelp,
    headers: grid.headers,
});

const connectedRow = connect(mapStateToProps)(Row);
export { connectedRow as Row };
