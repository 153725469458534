import classNames from 'classnames';
import moment from 'moment';
import { Component } from 'react';
import { connect } from 'react-redux';
import { cellEvents } from './cell.events';
import { gridActions as actions } from '../../../actions';
import { constants } from '../../../constants';
import { CustomDatePicker } from '../../common/date-range-custon-view/CustomDatePicker';
import { Tooltip, TooltipPlacement, TooltipTriggerType } from '../../common/Tooltip';

class DateCell extends Component {
    shouldComponentUpdate = nextProps =>
        this.props.hasFocus !== nextProps.hasFocus ||
        this.props.editing !== nextProps.editing ||
        this.props.value !== nextProps.value ||
        this.props.error !== nextProps.error ||
        this.props.showPlaceholder !== nextProps.showPlaceholder ||
        this.props.readonly !== nextProps.readonly;

    formatOrDefault = () => {
        const { column, value, draft } = this.props;

        if (!draft && typeof column.format === 'function') {
            return column.format(value);
        }

        return value ? moment(value).format(constants.dateFormat) : '';
    };

    handleChange = newValue => {
        const { dispatch, editing, onClick, columnName, readonly } = this.props;

        if (!readonly) {
            if (!editing) {
                onClick(columnName, true);
            }

            dispatch(actions.editing(moment(newValue).startOf('day').utcOffset(0, true).format()));
            dispatch(actions.applyEdit());
        }
    };

    render = () => {
        const { className, column, value, hasFocus, editing, error, showPlaceholder, placeholder, readonly } =
            this.props;

        const cellClassName = classNames(className, {
            [`cell-${column.name}`]: true,
            'has-focus': hasFocus,
            readonly,
        });

        return (
            <td
                className={cellClassName}
                onClick={cellEvents.handleClick.bind(this, column.name)}
                onCopy={cellEvents.handleCopy.bind(this)}
                onPaste={cellEvents.handlePaste.bind(this)}
            >
                <div className={classNames({ 'cell-item': true, error: !!error && !readonly })}>
                    {editing ? (
                        <Tooltip
                            className="grid-date-cell"
                            overlayClassName={classNames('grid-date-cell-tooltip', className)}
                            placement={TooltipPlacement.BottomLeft}
                            trigger={TooltipTriggerType.Click}
                            hideOnAnyAction={false}
                            visible={true}
                            overlay={() => (
                                <CustomDatePicker
                                    date={value ? moment(value).toDate() : new Date()}
                                    from={moment(value).toDate()}
                                    to={moment(value).toDate()}
                                    onDayClick={this.handleChange}
                                />
                            )}
                        >
                            <div>{this.formatOrDefault()}</div>
                        </Tooltip>
                    ) : (
                        <span className="cell-item-text">
                            {this.formatOrDefault() || (showPlaceholder && <span>{placeholder}</span>)}
                        </span>
                    )}
                    {hasFocus && !editing && <input autoFocus type="text" className="invisible" />}
                    {error && hasFocus && !readonly && <div className="form-error">{error}</div>}
                </div>
            </td>
        );
    };
}

const connectedText = connect()(DateCell);
export { connectedText as DateCell };
