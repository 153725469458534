import React, { Component } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { cellEvents } from './cell.events';
import { HelpPopover } from '../HelpPopover';
import MaskedInput from 'react-text-mask';

class Text extends Component {
    shouldComponentUpdate = nextProps =>
        !!this.props.hasFocus !== !!nextProps.hasFocus
        || !!this.props.editing !== !!nextProps.editing
        || this.props.value !== nextProps.value
        || this.props.error !== nextProps.error
        || !!this.props.showHelp !== !!nextProps.showHelp
        || !!this.props.showPlaceholder !== !!nextProps.showPlaceholder
        || !!this.props.readonly !== !!nextProps.readonly;

    formatOrDefault = () => {
        const { column, value, draft } = this.props;

        if (!draft && typeof column.format === 'function') {
            return column.format(value);
        }

        return value;
    }

    render = () => {
        const { className, column, value, hasFocus, editing, error, showHelp, showPlaceholder, placeholder, readonly, helpPopoverTitle } = this.props;
        const cellClassName = classNames(column.className, className, {
            [`cell-${column.name}`]: true,
            'has-focus': hasFocus,
            readonly: column.readonly || readonly
        });

        return (
            <td
                className={cellClassName}
                onClick={cellEvents.handleClick.bind(this, column.name)}
                onCopy={cellEvents.handleCopy.bind(this)}
                onPaste={cellEvents.handlePaste.bind(this)}
            >
                <div className={classNames({ 'cell-item': true, error: !!error && !readonly })}>
                    {
                        editing
                            ? column.mask
                                ? <MaskedInput
                                    autoFocus
                                    className="cell-item-control"
                                    mask={column.mask}
                                    guide={false}
                                    maxLength={column.length}
                                    value={value ?? ''}
                                    onChange={cellEvents.handleChange.bind(this)}
                                    onFocus={e => e.target.select()}
                                />
                                : <input
                                    className="cell-item-control"
                                    maxLength={column.length}
                                    autoFocus
                                    type="text"
                                    value={value || ''}
                                    onChange={cellEvents.handleChange.bind(this)}
                                    onFocus={e => e.target.select()}
                                />
                            : <span className="cell-item-text">{this.formatOrDefault() || (showPlaceholder && <span>{placeholder}</span>)}</span>
                    }
                    {hasFocus && !editing && <input autoFocus type="text" className="invisible" />}
                    {error && hasFocus && !readonly && <div className="form-error">{error}</div>}
                    {showHelp && helpPopoverTitle && <HelpPopover title={helpPopoverTitle}/>}
                </div>
            </td>
        );
    }
}

const connectedText = connect()(Text);
export { connectedText as Text };


