import React, { Component } from "react";
import { classnames } from "../../styles/classnames";

class PasswordStrengthValidator extends Component {
    state = {
        isValid: false
    }

    componentDidUpdate = () => {
        const { password, regexp, onValidate } = this.props;

        const isValid = new RegExp(regexp).test(password);

        if (this.state.isValid !== isValid) {
            this.setState({ isValid });

            onValidate(isValid);
        }
    }

    render = () => {
        const cssClass = classnames({
            'is-validation-passed': this.state.isValid
        });

        return <div className={cssClass}>{this.props.message}</div>;
    };
}

export default PasswordStrengthValidator;
