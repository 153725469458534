import React from 'react';
import cn from 'classnames';

export function FilterSwitch({ selected, disabled = false, caption, onClick }) {
    return (
        <div
            className={cn('filter-group-item', { selected, disabled })}
            onClick={() => onClick && !disabled && onClick()}
        >
            {caption}
        </div>
    )
}
