import React, { Component } from 'react';
import { connect } from 'react-redux';
import { gridActions } from '../../../actions';
import { HelpPopover } from '../HelpPopover';
import { classnames } from '../../../styles/classnames';
import { cellEvents } from './cell.events';


class Lookup extends Component {
    state = {
        lookupSelectedIndex: 0
    };

    shouldComponentUpdate = nextProps =>
        !!this.props.hasFocus !== !!nextProps.hasFocus
        || this.props.editing
        || nextProps.editing
        || this.props.value !== nextProps.value
        || this.props.error !== nextProps.error
        || !!this.props.showHelp !== !!nextProps.showHelp
        || !!this.props.showPlaceholder !== !!nextProps.showPlaceholder
        || !!this.props.readonly !== !!nextProps.readonly
        || !!this.props.disableUppercase !== !!nextProps.disableUppercase;

    componentDidUpdate = (prevProps, prevState) => {
        const { editing, lookupDataItems, dispatch } = this.props;
        if (editing || prevProps.editing) {
            dispatch(gridActions.blockInput(editing && !!lookupDataItems.length));
        }

        if (this._activeLookupItem &&
            this._scrollableLookupContainer &&
            this.props.lookupDataItems &&
            this.props.lookupDataItems.length &&
            prevState.lookupSelectedIndex !== this.state.lookupSelectedIndex) {

            const container = this._scrollableLookupContainer.getBoundingClientRect();
            const item = this._activeLookupItem.getBoundingClientRect();

            if (item.top < container.top || item.bottom > container.bottom) {
                this._activeLookupItem.scrollIntoView(false);
            }
        }

    }

    handleFormKeyDown = e => {
        if (e.keyCode === 13 || e.which === 13) {
            e.preventDefault();
            return false;
        }
    }

    render = () => {
        const {
            value,
            columnName,
            hasFocus,
            editing,
            error,
            maxLength,
            lookupDataItems,
            showHelp,
            showPlaceholder,
            placeholder,
            helpPopoverTitle,
            readonly
        } = this.props;

        const cssClasses = classnames({
            [`cell-${columnName}`]: true,
            'has-focus': hasFocus,
            readonly
        });

        return (
            <td
                className={cssClasses}
                onClick={this.handleClick}
                onCopy={cellEvents.handleCopy.bind(this)}
                onPaste={this.handlePaste}
            >
                <div className={classnames({ 'cell-item': true, error: !!error })}>
                    {
                        editing &&
                        <form id="noValidateForm" noValidate={true} onKeyDown={this.handleFormKeyDown}>
                                <input
                                    className="cell-item-control"
                                    maxLength={maxLength}
                                    autoFocus
                                    type="text"
                                    value={value || ''}
                                    onBlur={this.handleBlur}
                                    onChange={this.handleChange}
                                    onKeyDown={this.handleKeyDown}
                                    onFocus={e => e.target.select()}
                                />
                            </form>
                    }
                    {editing && this.renderLookup(lookupDataItems)}
                    {!editing && <span className="cell-item-text">{value || (showPlaceholder && <span>{placeholder}</span>)}</span>}
                    {hasFocus && !editing && <input autoFocus type="text" className="invisible" />}
                    {error && hasFocus && <div className="form-error">{error}</div>}
                    {showHelp && helpPopoverTitle && <HelpPopover title={helpPopoverTitle}/>}
                </div>
            </td>
        );
    }

    renderLookup = lookupData => {
        if (!lookupData.length) {
            return null;
        }

        const { onLookupItemSelected } = this.props;

        const items = lookupData.map((item, index) =>
            <li key={index}
                ref={node => this._activeLookupItem = index === this.state.lookupSelectedIndex ? node : this._activeLookupItem}
                className={classnames({ 'lookup-data-item': true, active: index === this.state.lookupSelectedIndex })}
                onClick={onLookupItemSelected.bind(this, item)}
            >
                {item.text} {item.description && <span>{item.description}</span>}
            </li >
        );

        return <ul ref={node => this._scrollableLookupContainer = node} className="cell-lookup">{items}</ul>;
    }

    handleClick = () => {
        const { editing, hasFocus, columnName, onClick, onLookupReset } = this.props;
        if (!editing) {
            this.setState({ lookupSelectedIndex: 0 });
            onLookupReset();
            onClick(columnName, hasFocus);
        }
    }

    handlePaste = e => {
        const { onSearchTermChange, dispatch } = this.props;
        const clipboardData = e.clipboardData || window.clipboardData;
        const clipboard = clipboardData.getData('text');

        if (clipboard && clipboard.indexOf('\t') < 0) {
            this.setState({ lookupSelectedIndex: 0 });
            dispatch(gridActions.edit());
            dispatch(gridActions.editing(clipboard));
            onSearchTermChange(clipboard);
        }

        e.preventDefault();
    }

    handleChange = e => {
        const { onSearchTermChange, dispatch, disableUppercase } = this.props;
        const value = e.target.value;

        this.setState({ lookupSelectedIndex: 0 });

        const formattedValue = disableUppercase ? value : value.toUpperCase();

        dispatch(gridActions.editing(formattedValue));
        onSearchTermChange(value);
    }

    handleKeyDown = e => {
        const { lookupDataItems = [], onLookupItemSelected, onLookupReset } = this.props;
        if (lookupDataItems.length) {
            if (e.keyCode === 13) { // enter
                onLookupItemSelected(lookupDataItems[this.state.lookupSelectedIndex])
            } else if (e.keyCode === 38 && this.state.lookupSelectedIndex > 0) { // move up
                this.setState({ lookupSelectedIndex: this.state.lookupSelectedIndex - 1 });
            } else if (e.keyCode === 40 && this.state.lookupSelectedIndex < lookupDataItems.length - 1) { // move down
                this.setState({ lookupSelectedIndex: this.state.lookupSelectedIndex + 1 });
            } else if (e.keyCode === 27) { // esc
                this.setState({ lookupSelectedIndex: 0 });
                onLookupReset();
            }
        }
    }
}

const connectedLookup = connect()(Lookup);
export { connectedLookup as Lookup };

