import React, { Component } from 'react';
import { connect } from 'react-redux';
import { gridColumns } from '../../../constants';
import { securitiesActions as actions, gridActions, changeColorActions } from '../../../actions';
import { classnames } from '../../../styles/classnames';
import { formatUtils } from '../../../utils';
import { ChangeColor } from '../../common';


class Color extends Component {
    shouldComponentUpdate = nextProps =>
        this.props.hasFocus !== nextProps.hasFocus
        || this.props.editing !== nextProps.editing
        || this.props.value !== nextProps.value

    render = () => {
        const { value, hasFocus, editing, dispatch } = this.props;
        const cssClasses = classnames({
            'cell-color': true,
            'has-focus': hasFocus
        });

        return (
            <td
                className={cssClasses}
                onClick={this.handleClick}
            >
                <div className="cell-item">
                    <span className="cell-item-text"><span className="pseudo-link">{formatUtils.formatColor(value)}</span></span>
                    {hasFocus && <input autoFocus type="text" className="invisible" />}
                    {hasFocus && !editing && <input autoFocus type="text" className="invisible" />}
                    {
                        editing &&
                        <ChangeColor
                            saveButtonText="Apply"
                            onCancel={this.handleEditCancel}
                            onDelete={this.handleDelete}
                            onSave={() => dispatch(actions.saveColor())}
                        />
                    }
                </div>
            </td>
        );
    }

    handleClick = () => {
        const { editing, hasFocus, onClick } = this.props;
        if (!editing) {
            onClick(gridColumns.color.name, hasFocus);
        }
    }

    handleDelete = () => {
        const { dispatch } = this.props;

        dispatch(gridActions.clearCell());
        dispatch(gridActions.applyEdit());
        dispatch(changeColorActions.reset());
        dispatch(gridActions.blockInput(false));
    }

    handleEditCancel = () => {
        const { dispatch } = this.props;

        dispatch(gridActions.cancelEdit());
        dispatch(gridActions.blockInput(false));
    }
}

const mapStateToProps = () => ({});

const connectedColor = connect(mapStateToProps)(Color);
export { connectedColor as Color }; 
