import { gridActions } from '../../actions/grid.actions';
import IconSVG from '../../styles/svg-icons';
import { useAppDispatch } from '../../effects/useAppDispatch';

interface Props {
    title?: string;
}

export function HelpPopover({ title }: Props) {
    const dispatch = useAppDispatch();

    return (
        <div className="popover popover-grid-help">
            <div className="popover-header">
                <p className="text-medium">
                    <IconSVG name="add-contour" width={16} height={16} />
                    {title || ''}
                </p>
                <button className="btn-close" onClick={() => dispatch(gridActions.hideHelp())}>
                    <IconSVG name="close" width={16} height={16} />
                </button>
            </div>
            <div className="popover-body">
                <ol>
                    <li>Enter manually.</li>
                    <li>Upload a file.</li>
                    <li>Copy and paste from Excel.</li>
                </ol>
            </div>
        </div>
    );
}
