import cn from 'classnames';
import { GridColumn, GridCurrentPosition, GridOrderBy } from '../../types/state/GridState';
import { gridActions } from '../../actions/grid.actions';
import IconSVG from "../../styles/svg-icons";
import { OnHoverTooltip } from '../common/OnHoverTooltip';
import { useAppDispatch } from '../../effects/useAppDispatch';

interface Props {
    column: GridColumn;
    position?: GridCurrentPosition;
    orderBy: GridOrderBy;
    markOptional?: boolean
}

export function HeaderItem({ column, position, orderBy, markOptional }: Props) {
    const dispatch = useAppDispatch();

    const classNames = cn(column.className, {
        ['cell-' + column.name]: true,
        'cell-sort': !column.disabledSort,
        [`sorted ${orderBy.direction}`]: orderBy?.columnName === column.name,
        highlight: position?.columnName === column.name,
    });

    const content = (
        <div className={cn("cell-title-wrapper", { error: column.headerError, "column-removable": column.canRemove })}>
            <span>
                {column.title}{column.renderTitle?.()}{markOptional && !column.required && ' (optional)'}
            </span>
            {column.required && <span className="text-red">*</span>}
            {!column.disabledSort && <IconSVG name="corner-arrow" className="icon-cell-sort" width={16} height={16} />}
            {
                column.canRemove &&
                <button className="btn-close" onClick={() => dispatch(gridActions.removeColumn(column.name))}>
                    <IconSVG name="close" width={16} height={16} />
                </button>
            }
        </div>
    );

    return (
        <th scope="col"
            key={column.name + column.title}
            onClick={column.disabledSort ? undefined : () => dispatch(gridActions.orderBy(column.name))}
            className={classNames}
        >
            {
                column.headerError
                    ? <OnHoverTooltip overlay={column.headerError}>{content}</OnHoverTooltip>
                    : content
            }
        </th>
    );

}
