import React from 'react';
import cn from 'classnames';
import { Primitive } from "../../../utils/differ/types";
import { gridActions } from '../../../actions/grid.actions';
import { FormError } from '../../controls/FormError';
import { HelpPopover } from '../HelpPopover';
import { ILookup } from '../../../types/state/GridState';
import { isRequesting, isRequestSuccess } from '../../../utils';
import { requestCancelationController } from '../../../services/request-cancelation-controller';
import { RequestState } from '../../../constants/request-state';
import { Preloader } from '../../common';
import { useAppSelector } from '../../../effects/useAppSelector';
import { logger } from '../../../logging/logger';
import { useDebounce } from '../../../effects/useDebounce';
import { isRequestCancelationError } from '../../../actions';
import { useAppDispatch } from '../../../effects/useAppDispatch';

const searchTermMinLength = 3;

export interface LookupDataItem {
    value?: Primitive;
    text: string;
    description?: string;
    payload?: any;
}

interface LookupProps {
    hasFocus?: boolean;
    editing?: boolean;
    value?: Primitive;
    error?: string;
    showHelp?: boolean;
    helpPopoverTitle?: string;
    showPlaceholder?: boolean;
    placeholder?: string;
    readonly?: boolean;
    maxLength?: number;
    disableUppercase?: boolean;
    columnName: string;
    lookupDataSource: ILookup;
    onClick: (columnsName: string, hasFocus: boolean) => void;
}

function LookupComponent({
    hasFocus,
    editing,
    value,
    error,
    showHelp,
    helpPopoverTitle,
    showPlaceholder,
    placeholder,
    readonly,
    maxLength,
    columnName,
    onClick,
    lookupDataSource,
    disableUppercase,
}: LookupProps & ILookup) {
    const classNames = cn({
        [`cell-${columnName}`]: true,
        'has-focus': hasFocus,
        readonly
    });
    const dispatch = useAppDispatch();

    const handleClick = () => {
        if (!editing) {
            onClick(columnName, Boolean(hasFocus));
        }
    }

    const handlePaste = (e: React.ClipboardEvent) => {
        const clipboardData = e.clipboardData || window.clipboardData;
        const clipboard = clipboardData?.getData('text')?.replace(/\r\n$/, '');

        if (clipboard && clipboard.indexOf('\t') < 0) {
            dispatch(gridActions.edit());
            dispatch(gridActions.editing(clipboard));
        }

        e.preventDefault();
    }

    const handleCopy = (e: React.ClipboardEvent) => {
        const clipboardData = e.clipboardData || window.clipboardData;
        clipboardData.setData('text', (value ?? '').toString());

        e.preventDefault();
    }

    return (
        <td
            className={classNames}
            onClick={handleClick}
            onCopy={handleCopy}
            onPaste={handlePaste}
        >
            <div className={cn('cell-item', { error: !!error })}>
                {
                    Boolean(editing) &&
                    <LookupEdit
                        value={value}
                        lookupDataSource={lookupDataSource}
                        maxLength={maxLength}
                        disableUppercase={disableUppercase}
                    />
                }
                {
                    !editing &&
                    <span className="cell-item-text">
                        {value ? value.toString() : (Boolean(showPlaceholder) && Boolean(placeholder) && <span>{placeholder}</span>)}
                    </span>
                }
                {Boolean(hasFocus) && !editing && <input autoFocus type="text" className="invisible" />}
                {Boolean(hasFocus) && <FormError message={error} />}
                {Boolean(showHelp) && Boolean(helpPopoverTitle) && <HelpPopover title={helpPopoverTitle} />}
            </div>
        </td>
    );
}

export const Lookup2 = React.memo(LookupComponent, (prev, next) => {
    return (
        Boolean(prev.hasFocus) === Boolean(next.hasFocus) &&
        Boolean(prev.editing) === Boolean(next.editing) &&
        Boolean(prev.showHelp) === Boolean(next.showHelp) &&
        Boolean(prev.showPlaceholder) === Boolean(next.showPlaceholder) &&
        Boolean(prev.readonly) === Boolean(next.readonly) &&
        prev.value === next.value &&
        (prev.error ?? '') === (next.error ?? '')
    );
})

interface LookupEditProps {
    value?: Primitive;
    maxLength?: number;
    lookupDataSource: ILookup;
    disableUppercase?: boolean;
}

function LookupEdit({ value, lookupDataSource, maxLength, disableUppercase }: LookupEditProps) {
    const dispatch = useAppDispatch();

    const fetchState = useFetchLookupItems(
        (value ?? '').toString(),
        lookupDataSource.compareCallback,
        lookupDataSource.fetchCallback);
    const lookupItemsState = useAppSelector(lookupDataSource.selector ? lookupDataSource.selector : () => null) ?? [];
    const lookupDataItems = lookupDataSource.fetchCallback ? fetchState.lookupItems : lookupItemsState;
    const requestState = lookupDataSource.fetchCallback ? fetchState.requestState : RequestState.success;

    const [lookupSelectedIndex, setLookupSelectedIndex] = React.useState(0);
    const scrollableLookupContainerRef = React.useRef<HTMLUListElement | null>(null);
    const activeLookupItemRef = React.useRef<HTMLLIElement | null>(null);

    const debounceDisabled =
        lookupDataSource.fetchCallback == null ||
        isRequestSuccess(fetchState.requestState) ||
        isRequesting(fetchState.requestState);

    const isLookupVisible =
        (lookupDataItems.length > 0 || isRequesting(requestState)) &&
        (value ?? '').toString().length > 0

    React.useEffect(() => {
        dispatch(gridActions.blockInput(isLookupVisible));
        return () => {
            dispatch(gridActions.blockInput(false));
        }
    }, [isLookupVisible, dispatch]);

    React.useLayoutEffect(() => {
        if (
            activeLookupItemRef.current &&
            scrollableLookupContainerRef.current &&
            lookupDataItems?.length) {
            const container = scrollableLookupContainerRef.current.getBoundingClientRect();
            const item = activeLookupItemRef.current.getBoundingClientRect();

            if (item.top < container.top || item.bottom > container.bottom) {
                activeLookupItemRef.current.scrollIntoView(false);
            }
        }
        // eslint-disable-next-line
    }, [lookupSelectedIndex]);

    React.useEffect(() => {
        return () => {
            fetchState.resetCallback();
        }
        // eslint-disable-next-line
    }, [])

    const handleChange = (value: string) => {
        setLookupSelectedIndex(0);
        dispatch(gridActions.editing(value));
    }

    const handleKeyDown = (e: React.KeyboardEvent) => {
        if (lookupDataItems.length) {
            if (e.keyCode === 13) { // enter
                lookupDataSource.onLookupItemSelected(lookupDataItems[lookupSelectedIndex])
            } else if (e.keyCode === 38 && lookupSelectedIndex > 0) { // move up
                setLookupSelectedIndex(lookupSelectedIndex - 1);
            } else if (e.keyCode === 40 && lookupSelectedIndex < lookupDataItems.length - 1) { // move down
                setLookupSelectedIndex(lookupSelectedIndex + 1);
            } else if (e.keyCode === 27) { // esc
                setLookupSelectedIndex(0);
                fetchState.resetCallback();
                dispatch(gridActions.cancelEdit());
            }
        }
    }

    const handleFormKeyDown = (e: React.KeyboardEvent) => {
        if (e.keyCode === 13 || e.which === 13) {
            e.preventDefault();
            return false;
        }
    }

    const renderLookup = () => {
        if (!isLookupVisible) return null;

        return (
            <div className="cell-lookup">
                <Preloader small={true} inProgress={isRequesting(fetchState.requestState)}>
                    <ul ref={scrollableLookupContainerRef}>
                        {
                            lookupDataItems.map((item, index) =>
                                <li
                                    key={index}
                                    ref={node => activeLookupItemRef.current = index === lookupSelectedIndex ? node : activeLookupItemRef.current}
                                    className={cn('lookup-data-item', { active: index === lookupSelectedIndex })}
                                    onClick={() => lookupDataSource.onLookupItemSelected(item)}
                                >
                                    {item.text} {Boolean(item.description) && <span>{item.description}</span>}
                                </li >
                            )
                        }
                    </ul>
                </Preloader>
            </div>
        );
    }

    return (
        <>
            <form id="noValidateForm" noValidate={true} onKeyDown={handleFormKeyDown}>
                <LookupInput
                    className="cell-item-control"
                    maxLength={maxLength}
                    autoFocus
                    type="text"
                    value={(value ?? '').toString()}
                    onChange={debounceDisabled ? e => handleChange(e.target.value) : undefined}
                    onDebouncedChange={debounceDisabled ? undefined : handleChange}
                    onKeyDown={handleKeyDown}
                    onFocus={e => e.target.select()}
                    disableUppercase={disableUppercase}
                />
            </form>
            {renderLookup()}
        </>

    );
}

interface LookupInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
    onDebouncedChange?: (debouncedValue: string) => void;
    disableUppercase?: boolean;
}

function LookupInput({ value, onDebouncedChange, onChange, disableUppercase, ...rest }: LookupInputProps) {
    const [_value, setValue] = React.useState(value);
    const debouncedValue = useDebounce(_value, 500);

    React.useEffect(() => {
        onDebouncedChange?.((debouncedValue ?? '').toString());
        // eslint-disable-next-line
    }, [debouncedValue]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setValue(e.target.value);
        onChange?.(e);
    }

    const stringValue = (_value ?? '').toString();

    return (
        <input {...rest} value={disableUppercase ? stringValue : stringValue.toUpperCase()} onChange={handleChange} />
    );
}

function useFetchLookupItems(
    searchTerm: string,
    compareLookupItemClallback?: (searchTerm: string, item: LookupDataItem) => boolean,
    fetchLookupItemsCallback?: (searchTerm: string, requestCancelationSignal: AbortSignal) => LookupDataItem[]
) {
    const [requestState, setRequestState] = React.useState(RequestState.none);
    const cachedItems = React.useRef<LookupDataItem[]>([]);
    const [lookupItems, setLookupItems] = React.useState<LookupDataItem[]>([]);

    const searchTermRef = React.useRef<string>('');

    const resetCallback = () => requestCancelationController.abort(searchTermRef.current)

    React.useEffect(() => {
        const requestLookupItems = async () => {
            setRequestState(RequestState.request);
            cachedItems.current = [];

            searchTermRef.current = searchTerm;
            const abortSignal = requestCancelationController.signal(searchTerm);

            try {
                const items = await fetchLookupItemsCallback!(searchTerm, abortSignal);
                cachedItems.current = items;
                setRequestState(RequestState.success);
                setLookupItems(items);
            } catch (e) {
                if (!isRequestCancelationError(e)) {
                    searchTermRef.current = '';
                    cachedItems.current = [];
                    setLookupItems([]);
                    setRequestState(RequestState.failure);
                    logger.exception(e, 'Failed to feetch grid lookup data items');
                }
            } finally {
                requestCancelationController.reset(searchTerm);
            }
        }

        const searchLookupItemsFromCache = () => {
            const searchTermLowerCase = searchTerm.toLowerCase().trim();
            const filtered = cachedItems.current.filter(i =>
                compareLookupItemClallback?.(searchTermLowerCase, i) ??
                i.text.toLowerCase().startsWith(searchTermLowerCase)
            );
            setLookupItems(filtered);
        }

        const abortRequest = () => {
            requestCancelationController.abort(searchTermRef.current);
            searchTermRef.current = '';
            setRequestState(RequestState.none);
        }

        if (isRequesting(requestState) && searchTerm !== searchTermRef.current) {
            abortRequest();
        }

        const canUseCache =
            searchTermRef.current !== '' &&
            searchTerm.toLowerCase().startsWith(searchTermRef.current.toLowerCase());

        if (canUseCache) {
            searchLookupItemsFromCache();
        } else if (fetchLookupItemsCallback && searchTerm.length >= searchTermMinLength) {
            requestLookupItems();
        }
        // eslint-disable-next-line
    }, [searchTerm, requestState]);

    return { requestState, lookupItems, resetCallback };
}
