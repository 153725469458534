import React from 'react';
import { FilterSwitch } from './FilterSwitch';

export function FilterMultiPicker({ sourceItems = [], disabled = false, required = true, onChangeItemSelection }) {
    const handleItemSelectionChange = item => {
        if (!item.selected || !required || sourceItems.filter(i => i.selected).length > 0) {
            onChangeItemSelection && onChangeItemSelection(item);
        }
    };

    const items = sourceItems.map((o, i) =>
        <FilterSwitch
            key={i}
            caption={o.jsxTitle || o.text || o.value}
            selected={o.selected}
            disabled={o.disabled || disabled}
            onClick={() => handleItemSelectionChange(o)}
        />
    );

    return <div className='filter-group flex-row'>{items}</div>
}
