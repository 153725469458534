import { isEqual } from 'lodash';
import { useDispatch } from 'react-redux';
import { v1 as uuid } from 'uuid';
import { FilterRangeGroup, FilterType } from '../../types/filters/FilterGroup';
import { BwicFilterType, CommonFilter, TFilterType } from '../../types/filters/FilterState';
import { Filters, FiltersProps } from './Filters';
import { numericUtils } from '../../utils';
import { filterUtils } from '../../utils/filtering/filter.utils';
import { usePageConfig } from '../common/pageConfig/usePageConfig';
import { PageConfigType } from '../../types/page-config/PageConfigType';
import { AlertOption } from '../../types/email-preferences/EmailPreferences';
import { FiltersManagement } from '../amrPipeline/aggregated/filters-management/FiltersManagement';
import { useEffect, useRef, useState } from 'react';
import { getSerializedFilter } from '../../utils/filtering/serializers/bwic/serializeFilter';
import { UserConfigFilter } from '../../types/user-config/UserConfigFilter';
import { createFilterActions } from '../../actions/filter.actions';
import { user } from '../../user/user';
import { textLabels } from '../../constants/text-labels';
import { FilterManagementForm } from '../amrPipeline/aggregated/filters-management/types';

const isApplyDisabled = (
    filterType: TFilterType,
    visibleFilters: string[],
    filter: CommonFilter,
    lastAppliedFilter: CommonFilter | undefined,
    isLoading: boolean) => {

    const hasErrors = () => visibleFilters.some(key => {
        const f = filter[key];
        if (f.type === FilterType.Range) {
            const fromRange = (f as FilterRangeGroup).filter.from;
            const toRange = (f as FilterRangeGroup).filter.to;

            if (
                numericUtils.isNumber(fromRange) &&
                numericUtils.isNumber(toRange) &&
                Number(fromRange) > Number(toRange)) return true;
        }
        return false;
    });

    return isLoading || hasErrors() || !filterUtils.isFilterChanged(filterType, filter, lastAppliedFilter);
}

interface BwicFilterProps extends FiltersProps {
    lastAppliedFilter?: CommonFilter;
    pageConfigType: PageConfigType;
    withAlerts?: boolean;
    renderFilterAlertsPopup?: (filter: UserConfigFilter, onClose: () => void) => React.ReactNode;
    alertOptions?: AlertOption[];
    filterAlertPopupDescription?: string;
    filterAlertPopupTooltips?: { [key in AlertOption]?: string };
    onFilterCreate?: (form: FilterManagementForm) => void;
    onFilterUpdate?: (form: FilterManagementForm) => void;
}

export const BwicFilters = (props: BwicFilterProps) => {
    const [isFilterCollapsed, setFiltersCollapseStatus] = useState(false);
    const { visibleFilters, filter, lastAppliedFilter, isSearching, filterType, filterModified,
        selectedFilterReferenceName, withAlerts = false, renderFilterAlertsPopup, filterAlertPopupTooltips,
        onFilterCreate, onFilterUpdate } = props;
    const applyDisabled = isApplyDisabled(filterType, visibleFilters, filter, lastAppliedFilter, isSearching);
    const bwicFilterType = filterType as BwicFilterType;
    const canUseSavedFilters = !props.saveFilterBlockerFeature || user.hasFeatures(props.saveFilterBlockerFeature);

    const { config, filterActions, saveAlerts } = usePageConfig(props.pageConfigType, true, bwicFilterType);

    useSavedFilterAutoSelect(
        !props.saveFilterVisible || !canUseSavedFilters,
        bwicFilterType,
        lastAppliedFilter,
        config?.filters,
        selectedFilterReferenceName
    );

    const handleFilterAlertSave = (referenceName: string, alertOption: AlertOption, isDefault: boolean) => {
        if (!config?.filters) {
            return;
        }

        const updatedFilters = config?.filters.map(f => f.referenceName === referenceName
            ? { ...f, alertOption, default: isDefault } : f);
        saveAlerts(updatedFilters);
    }

    const handleDeleteFilter = (referenceName: string) => filterActions.deleteFilter(referenceName);
    const filterAlertPopupDescription = props.filterAlertPopupDescription
        ? props.filterAlertPopupDescription
        : textLabels.savedFiltersBwicMonitor;

    const handleFilterCreate = (form: FilterManagementForm) => {
        if (onFilterCreate) {
            return onFilterCreate(form);
        }

        const newFilter: UserConfigFilter = {
            name: form.name,
            referenceName: uuid(),
            default: form.makeDefault,
            filter: getSerializedFilter(filter),
            alertOption: form.receiveEmailNotification
        };

        filterActions.addFilter(newFilter);
    }

    const handleFilterUpdate = (form: FilterManagementForm) => {
        if (onFilterUpdate) {
            return onFilterUpdate(form);
        }

        const existingFilter = config?.filters.find(f => f.referenceName === form.referenceName);
        if (!existingFilter) return;

        const updatedFilter = {
            ...existingFilter,
            filter: getSerializedFilter(filter),
            default: form.makeDefault,
            alertOption: form.receiveEmailNotification

        }

        filterActions.updateFilter(updatedFilter)
    }

    const onSavedFilterChange = () => {
        props.onApply();
        setFiltersCollapseStatus(false);
    }

    return (
        <>
            <Filters
                {...props}
                filtersCollapseStatus={isFilterCollapsed}
                setFiltersCollapseStatus={setFiltersCollapseStatus}
                filters={config?.filters}
                applyDisabled={applyDisabled}
                alertOptions={props.alertOptions}
                defaultAlertOption={props.defaultAlertOption}
                alertOptionInfo={filterAlertPopupTooltips}
                renderCustomAlertOptions={props.renderCustomAlertOptions}
                onFilterSave={handleFilterCreate}
                onFilterUpdate={handleFilterUpdate}
            />
            {props.saveFilterVisible && (
                <div className="flex-row flex-item-right save-manage-filters flex-none">
                    <FiltersManagement
                        withAlerts={withAlerts}
                        alertOptionInfo={filterAlertPopupTooltips}
                        onMakeDefault={filterActions.defaultFlagChange}
                        onResetDefault={filterActions.defaultFlagChange}
                        onSaveFilterAlert={handleFilterAlertSave}
                        onDeleteFilter={handleDeleteFilter}
                        filters={config?.filters || []}
                        filterChanged={props.filterChanged}
                        filterModified={filterModified}
                        filterType={bwicFilterType}
                        onChange={onSavedFilterChange}
                        selectedFilterReferenceName={selectedFilterReferenceName}
                        blockerFeature={props.saveFilterBlockerFeature}
                        renderFilterAlertsPopup={renderFilterAlertsPopup}
                        alertOptions={props.alertOptions}
                        filterAlertPopupDescription={filterAlertPopupDescription}
                    />
                    <div className="vertical-divider" />
                </div>
            )}
        </>
    );
}

// Auto restore selected filter value in filter management drop-down (FilterManagement.tsx) 
function useSavedFilterAutoSelect(
    disabled: boolean,
    filterType: BwicFilterType,
    lastAppliedFilter?: CommonFilter,
    savedFilters?: UserConfigFilter[],
    selectedFilterReferenceName?: string,
) {
    const dispatch = useDispatch();
    const appliedRef = useRef(false);

    useEffect(() => {
        if (!disabled &&
            !appliedRef.current &&
            lastAppliedFilter &&
            savedFilters?.length &&
            !selectedFilterReferenceName) {
            appliedRef.current = true;
            const serializedFilter = getSerializedFilter(lastAppliedFilter);
            const filterMatch = savedFilters?.find(f => isEqual(f.filter, serializedFilter));

            if (filterMatch) {
                const filterActions = createFilterActions(filterType);
                dispatch(filterActions.selectFilterFromConfig(filterMatch));
            }
        }
    }, [filterType, lastAppliedFilter, savedFilters, selectedFilterReferenceName, disabled, dispatch])
}
