import cn from 'classnames';
import { useSelector } from 'react-redux';
import { gridActions, notificationActions } from '../../../actions';
import { FileIcon, FormError, ProgressCircle } from '../../controls';
import { useUploadDocument } from '../../documents/useUploadDocument';
import { DocumentStoreType } from '../../../types/document/Document';
import { AppState } from '../../../types/state/AppState';
import { RequestState } from '../../../constants/request-state';
import { GridDataItem, GridItemUploadedFile, GridUploadStatus } from '../../../types/state/GridState';
import { errorMessages } from '../../../constants';
import { useAppDispatch } from '../../../effects/useAppDispatch';

type Props = {
    hasFocus?: boolean;
    editing?: boolean;
    value?: GridItemUploadedFile;
    error?: string;
    showHelp?: boolean;
    helpPopoverTitle?: string;
    showPlaceholder?: boolean;
    placeholder?: string;
    readonly?: boolean;
    maxSize: number;
    acceptedTypes: string[];
    columnName: string;
    documentStoreType: DocumentStoreType;
    onClick: (columnsName: string, hasFocus: boolean) => void;
    dataItem: GridDataItem<any>;
    readonlyCallback?: (dataItem: GridDataItem<any>) => boolean;
};

export const FileUpload = ({
    columnName,
    documentStoreType,
    value,
    editing,
    error,
    hasFocus,
    maxSize,
    acceptedTypes,
    readonly,
    dataItem,
    readonlyCallback = () => false,
    onClick,
}: Props) => {
    const dispatch = useAppDispatch();
    const { requestStateSaveDocument, onUploadDocument, progress } = useUploadDocument(documentStoreType);
    const position = useSelector((state: AppState) => state.grid.position);

    const classNames = cn({
        [`cell-${columnName}`]: true,
        'has-focus': hasFocus,
        readonly,
    });

    const handleClick = (e: React.MouseEvent) => {
        if (!editing) {
            onClick(columnName, Boolean(hasFocus));
        }
    };

    async function uploadFile(file: File) {
        dispatch(gridActions.setUploadState(GridUploadStatus.UploadingSingle));
        dispatch(gridActions.addFileToDataItem({ name: file.name }, position));

        const newDocument = await onUploadDocument(file);

        dispatch(gridActions.setUploadState(GridUploadStatus.None));
        dispatch(gridActions.addFileToDataItem({ ...newDocument }, position));
        dispatch(gridActions.blockInput(false));
    }

    const deleteFile = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        dispatch(gridActions.addFileToDataItem(null, position));
    };

    const handleLoadFile = (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files![0];

        if (!file) {
            return;
        }

        const splitFile = file.name.split('.');
        const fileExtension = splitFile[splitFile.length - 1];

        if (file.size > maxSize * 1024 * 1024) {
            dispatch(
                notificationActions.notificationAddErrorMessage(
                    `The file you are trying to upload is more than ${maxSize}mb. Please choose another one.`,
                    'The file is too big',
                ),
            );

            return;
        }

        if (!acceptedTypes.some(ext => ext.toLowerCase() === fileExtension.toLowerCase())) {
            dispatch(
                notificationActions.notificationAddErrorMessage(
                    errorMessages.documentInvalidFileTypeText(
                        fileExtension,
                        acceptedTypes.map(ext => `.${ext}`).join(','),
                    ),
                    errorMessages.documentInvalidFileTypeTitle,
                ),
            );

            return;
        }

        uploadFile(file);
    };

    const renderFileInput = () =>
        !readonlyCallback(dataItem) && (
            <label className="cell-item-document cell-item-document-add">
                <FileIcon isNew={true} />
                <span>Add File</span>
                <input
                    style={{ display: 'none' }}
                    id="file"
                    name="file"
                    type="file"
                    placeholder="Add file"
                    accept={acceptedTypes.join(',')}
                    onChange={handleLoadFile}
                />
            </label>
        );

    const renderFileControls = (fileName: string) => (
        <span className="cell-item-document">
            <FileIcon filename={fileName} />
            <span className="text-medium text-ellipsis file-name">{fileName}</span>
            {requestStateSaveDocument === RequestState.request ? (
                <ProgressCircle progress={progress[fileName]} />
            ) : (
                hasFocus && (
                    <button onClick={deleteFile} className="btn-link btn-danger">
                        <i className="icon icon-delete" />
                    </button>
                )
            )}
        </span>
    );

    return (
        <td className={classNames} onClick={handleClick}>
            <div className={cn('cell-item', { error: !!error })}>
                {!value?.name ? renderFileInput() : renderFileControls(value.name)}
                {Boolean(hasFocus) && <FormError message={error} />}
            </div>
        </td>
    );
};
