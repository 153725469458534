import React from 'react';
import { FeatureButton } from '../access/FeatureButton';

export function FilterButton({ className = '', caption = '', children, show = true, disabled = false, onClick }) {
    const buttonCaption = children ? children : caption;
    if (show) {
        return (
            <FeatureButton
                className={className || 'btn btn-main btn-sm'}
                disabled={disabled}
                onClick={() => onClick && onClick()}
            >
                {buttonCaption}
            </FeatureButton>
        );
    }
    return null;
}

FilterButton.defaultProps = {
    children: null
}
