import { useAppSelector } from '../../effects/useAppSelector';
import { GridDataItem, GridUploadStatus } from '../../types/state/GridState';
import { gridActions } from '../../actions/grid.actions';
import { useAppDispatch } from '../../effects/useAppDispatch';

interface Props {
    disabled?: boolean;
    onDelete?: () => void;
}

export function DeleteRowsWithErrorButton({ disabled = false, onDelete }: Props) {
    const dispatch = useAppDispatch();
    const hasErrors = useAppSelector(s =>
        s.grid.headers?.some(h => h.headerError) ||
        s.grid.dataItems.some((i: GridDataItem<any>) => !i.draft && i.errors?.length)
    );
    const isFileUploading = useAppSelector(s => s.grid.upload.status === GridUploadStatus.Uploading);
    const isDataProcessing = useAppSelector(s => s.grid.isDataProcessing);
    
    if (hasErrors) {
        const handleClick = () => {
            if (onDelete) {
                onDelete();
            } else {
                dispatch(gridActions.deleteDataItemsWithErrors());
            }
        }

        return (
            <button
                type="button"
                className="btn btn-link btn-danger"
                disabled={disabled || isFileUploading || isDataProcessing}
                onClick={handleClick}
            >
                <i className="icon icon-delete" />Delete all records with errors
            </button>
        );
    }

    return null;
}
