import { useSelector } from "react-redux"
import { gridActions } from "../../actions"
import { AppState } from "../../types/state/AppState";
import { GridDataItem } from "../../types/state/GridState";
import { useAppDispatch } from "../../effects/useAppDispatch";

interface DeleteFlaggedItemsButtonProps {
    disabled: boolean;
}

export const DeleteFlaggedItemsButton = ({ disabled }: DeleteFlaggedItemsButtonProps) => {
    const dispatch = useAppDispatch();
    const hasFlaggedItems = useSelector((s: AppState) => s.grid.dataItems.some((i: GridDataItem<any>) => i.isFlagged));

    if (hasFlaggedItems) {
        return (
            <button
                disabled={disabled}
                type="button"
                className="btn-link btn-danger"
                onClick={() => dispatch(gridActions.deleteFlaggedDataItems())}
            >
                <i className="icon icon-delete" />
                <span className="align-middle">Delete Flagged</span>
            </button>
        )
    }

    return null;
}