import React, { Component } from "react";
import { connect } from 'react-redux';
import { errorMessages } from '../../constants';

class ValidationMessage extends Component {
    state = {
        message: null,
        invalidated: false
    }

    shouldComponentUpdate = nextProps => nextProps.displayErrors;

    componentDidMount = () => this.target = document.querySelector(`[name="${this.props.for}"]`);

    componentDidUpdate = () => this.checkValidity();

    componentWillUnmount = () => {
        this.target && this.target.removeEventListener('change', this.checkValidity);
        this.target && this.target.removeEventListener('blur', this.checkValidity);
    }

    render = () => this.props.displayErrors && !!this.state.message
        ? <div className="form-error">{this.state.message}</div>
        : null;

    checkValidity = () => {
        const { onCustomValidate, onError } = this.props;

        if (typeof onCustomValidate !== 'undefined') {
            const customValidationResult = onCustomValidate(this.target.value);
            if (typeof customValidationResult.message === 'undefined') {
                this.target.setCustomValidity(customValidationResult ? '' : 'false');
            } else {
                this.target.setCustomValidity(customValidationResult.message);
            }
        }

        const message = this.getMessage(this.target.validity);

        if (this.target.validity.valid) {
            this.target.classList.remove('is-invalid');
        } else {
            this.target.classList.add('is-invalid');
            if(onError && typeof onError === 'function' && this.target){
                onError(this.target)
            }
        }

        if (this.state.message !== message) {
            this.setState({ message });
        }

        this.setValidityTrackingState();
    };

    getMessage = validity => {
        if (validity.valid) {
            return null;
        }

        const {
            requiredMessage = errorMessages.empty,
            customMessage = this.target.validationMessage === 'false'
                ? errorMessages.invalidValue
                : this.target.validationMessage,
            defaultMessage = errorMessages.invalidValue
        } = this.props;

        if (validity.valueMissing) {
            return requiredMessage;
        } else if (validity.customError) {
            return customMessage;
        }

        return defaultMessage;
    };

    setValidityTrackingState = () => {
        const { message, invalidated } = this.state;

        if (message && !invalidated) {
            this.target.addEventListener('change', this.checkValidity);
            this.target.addEventListener('blur', this.checkValidity);
            this.setState({ invalidated: true });
        } else if (!message && invalidated) {
            this.target.removeEventListener('change', this.checkValidity);
            this.target.removeEventListener('blur', this.checkValidity);
            this.setState({ invalidated: false });
        }
    }
}

const mapStateToProps = ({ forms }) => ({
    displayErrors: forms.displayErrors,
    triggerValidation: forms.triggerValidation
});

const connectedValidationMessage = connect(mapStateToProps)(ValidationMessage);
export { connectedValidationMessage as ValidationMessage };
