import { useSelector } from "react-redux"
import { gridActions } from "../../actions"
import { AppState } from "../../types/state/AppState";
import { GridDataItem } from "../../types/state/GridState";
import { useAppDispatch } from "../../effects/useAppDispatch";

interface DeleteAllButtonProps {
    disabled: boolean;
}

export const DeleteAllButton = ({ disabled }: DeleteAllButtonProps) => {
    const dispatch = useAppDispatch();
    const hasDataItems = useSelector((s: AppState) => s.grid.dataItems.some((i: GridDataItem<any>) => !i.draft));
    return (
        <button
            className="btn-link btn-danger"
            disabled={disabled || !hasDataItems}
            onClick={() => dispatch(gridActions.clear())}
        >
            <i className="icon icon-delete" />
            <span className="align-middle">Delete All</span>
        </button>
    )
}