import React from 'react';
import cn from 'classnames';

export function FilterSection({ title = '', className = '', children }) {
    return (
        <div className={cn('filter-section', className)}>
            {title && <label className="form-label">{title}</label>}
            {children}
        </div>
    )
}
