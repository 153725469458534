import { useEffect } from "react";
import { useCollateralManagers } from "../../../effects/data-accessors/useCollateralManagers";
import { FilterOption } from "../../../types/filters/FilterOption";
import { isRequestFailed, isRequestSuccess } from '../../../utils';
import { useDispatch } from "react-redux";
import { BwicFilterType } from "../../../types/filters/FilterState";
import { createFilterActions } from "../../../actions/filter.actions";

export const useManagersFilterData = (filterType: BwicFilterType, filterKey: string = 'managers') => {
    const dispatch = useDispatch();
    const filterActions = createFilterActions(filterType);
    const { requestState: managersRequestState, items: managers } = useCollateralManagers();

    useEffect(() => {
        if (isRequestSuccess(managersRequestState) || isRequestFailed(managersRequestState)) {
            const managersOptions: FilterOption[] = [...managers]
                .sort((a, b) => a.legalName.localeCompare(b.legalName))
                .map(cm =>
                ({
                    value: cm.referenceName,
                    text: cm.legalName,
                    selected: false,
                    visible: true,
                    disabled: false,
                })) as FilterOption[];
            dispatch(filterActions.storeFilterData.success({
                filterType: filterType,
                filterName: filterKey,
                data: managersOptions
            }))
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterType, filterKey, managersRequestState, dispatch])

    return managersRequestState;
}
