import { gridActions as actions } from '../../../actions';

export const cellEvents = {
    handleCopy,
    handlePaste,
    handleClick,
    handleChange,
    handleCloseHelp
};

function handleCopy(e) {
    const clipboardData = e.clipboardData || window.clipboardData;
    clipboardData.setData('text', this.props.value);

    e.preventDefault();
}

function handlePaste(e) {
    const { dispatch, column, readonly } = this.props;
    const clipboardData = e.clipboardData || window.clipboardData;
    const clipboard = clipboardData?.getData('text')?.replace(/^\r?\n+|\r?\n+$/g, '');
    const isReadonly = readonly || column?.readonly;

    if (!isReadonly &&
        clipboard &&
        clipboard.indexOf("\t") < 0 &&
        clipboard.indexOf("\n") < 0) {
        const parsedValue = actions.parseValue(clipboard, column);
        if (!column.pattern || column.pattern.test(parsedValue)) {
            dispatch(actions.edit());
            dispatch(actions.editing(parsedValue));
        }
    }

    e.preventDefault();
}

function handleClick(columnName) {
    const { editing, hasFocus, column, onClick } = this.props;
    const readonly = this.props.readonly || column?.readonly;

    if (!editing && (!hasFocus || (hasFocus && !readonly))) {
        onClick(columnName, hasFocus);
    }
}

function handleChange(e) {
    if (!this.props.column?.readonly && !this.props.readonly) {
        this.props.dispatch(actions.editing(e.target.value));
    }
}

function handleCloseHelp(e) {
    this.props.dispatch(actions.hideHelp());
    e.stopPropagation();
}
