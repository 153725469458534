import React, { useRef, useEffect } from 'react';
import { FilterChip } from './FilterChip';

export function ChipList({ list = [], selectionIndex, onRemove }) {
    const refList = useRef(null);
    const refChildren = useRef([]);
    const refListLength = useRef(0);

    useEffect(() => {
        if (selectionIndex !== null && refChildren.current[selectionIndex] && refList.current) {
            let offset = 0;

            for (let i = 0; i <= selectionIndex; i++) {
                offset += refChildren.current[i].offsetWidth;
            }
            refList.current.scrollLeft = offset - refList.current.clientWidth + 15;
        }
    }, [selectionIndex]);

    useEffect(() => {
        if (refList.current && refListLength.current < list.length) {
            refList.current.scrollLeft = refList.current.scrollWidth;
            refListLength.current = list.length;
        } else {
            refListLength.current = list.length;
        }
    }, [list]);

    function renderChips() {
        refChildren.current = [];
        const renderedList = [];
        for (let i = 0; i < list.length; i++) {
            renderedList.push(
                <FilterChip
                    key={i}
                    ref={node => refChildren.current[i] = node}
                    selected={selectionIndex === i}
                    text={list[i]}
                    onRemove={() => onRemove(i)}
                />
            );
        }
        return renderedList;
    }

    return (
        <div ref={refList} className="chip-list">{renderChips()}</div>
    );
}
ChipList.defaultProps = {
    selectionIndex: null,
    onRemove: null
}

