import React, { useRef } from 'react';
import classNames from 'classnames';
import { useField, useFormikContext } from 'formik';
import { FormControlLoader } from '../common';
import { useFocusOnError } from '../../effects/useFocusOnError';

export function InputField({ label, className = '', markRequired, loading = false, ...props }) {
    const fieldRef = useRef(null);
    const formik = useFormikContext();
    useFocusOnError({ fieldRef, name: props.name });
    const [field, meta] = useField(props);
    return (
        <>
            {label && <label className="form-label">
                {label}&nbsp;{markRequired && <span className="text-red">*</span>}
            </label>}
            <div className="form-control-wrapper">
                <input
                    ref={fieldRef}
                    className={classNames('form-control', { 'is-invalid': meta.touched && meta.error && formik.submitCount }, className)}
                    {...field}
                    value={field.value || ''}
                    autoComplete="new-password"
                    list="autocompleteOff" // disable auto-complete in IE & EDGE
                    {...props}
                />
                {
                    meta.touched && meta.error && !!formik.submitCount &&
                    <div className="form-error">{meta.error}</div>
                }
                <FormControlLoader inProgress={loading}/>
            </div>
        </>
    )
}
