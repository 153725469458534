import * as React from 'react';
import { useSelector } from 'react-redux';
import { constants, uploadStatus } from '../../constants';
import { AppState } from '../../types/state/AppState';

interface Props {
    onClick?: (file: File) => void;
    onFiles?: (files: FileList) => void;
    acceptedExtensions?: string;
}

export function SelectFile({ onClick, onFiles, acceptedExtensions }: Props) {
    const isDataProcessing: boolean = useSelector((s: AppState) => !!s.grid.isDataProcessing);
    const isFileUploading: boolean = useSelector((s: AppState) => s.grid.upload.status === uploadStatus.uploading);

    const fileInput = React.useRef<HTMLInputElement>(null);

    const isMultiple = typeof onFiles === 'function'

    const handleUploadSecurities = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (onClick && e.target.files?.length) {
            onClick(e.target.files[0]);
            e.target.value = ""
        }
        if (onFiles && e.target.files?.length) {
            onFiles(e.target.files);
            e.target.value = ""
        }
    }

    return (
        <>
            <button
                className="btn-link secondary"
                type="button"
                onClick={() => fileInput?.current?.click()}
                disabled={isFileUploading || isDataProcessing}
            >
                select manually
            </button>
            <input
                ref={fileInput}
                type="file"
                multiple={isMultiple}
                accept={acceptedExtensions || constants.acceptedTemplateExtensions}
                style={{ display: 'none' }}
                onChange={handleUploadSecurities}
            />
        </>
    );

}
