import { gridActions } from '../../actions';
import { OnHoverTooltip } from '../common';
import IconSVG from '../../styles/svg-icons';
import { useAppDispatch } from '../../effects/useAppDispatch';

interface Props { index: number; }

export function AddRowButton({ index }: Props) {
    const dispatch = useAppDispatch();
    return (
        <div className="action-bottom">
            <OnHoverTooltip overlay="Insert row">
                <button
                    onClick={() => dispatch(gridActions.addRow(index + 1))}
                    className="btn-link btn-action-add"
                >
                    <IconSVG name="plus-round" width="16" height="16" />
                </button>
            </OnHoverTooltip>
        </div>
    );
}
