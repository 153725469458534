import { useField, useFormikContext } from 'formik';
import cn from 'classnames';
import { DropDownListItem } from '../controls/DropDownList';
import { SelectSearch } from '../controls/SelectSearch';
import { constants } from '../../constants';

const resetOption = {
    value: '',
    text: constants.emptyPlaceholder,
    selected: false,
};

interface SelectSearchFieldProps {
    name: string;
    label: string;
    disabled?: boolean;
    markRequired: boolean;
    options: DropDownListItem[];
    withSearch?: boolean;
    placeholder?: string;
    className?: string;
    withResetOption?: boolean;
}

export function SelectSearchField({
    label,
    markRequired,
    disabled = false,
    options,
    placeholder,
    className = '',
    withResetOption,
    ...props
}: SelectSearchFieldProps) {
    const [field, meta] = useField(props);
    const formik = useFormikContext();
    const optionList = withResetOption ? [resetOption, ...options] : options;

    return (
        <>
            <label className="form-label">
                <div className="form-control-wrapper">
                    {label} {markRequired && <span className="text-red">*</span>}
                    <SelectSearch
                        label={label}
                        disabled={disabled}
                        markRequired={markRequired}
                        options={optionList}
                        placeholder={placeholder}
                        className={cn(className, {"is-invalid": meta.touched && meta.error})}
                        onChange={(value) => formik.setFieldValue(field.name, value === '' ? null : value)}
                    />
                    {
                        meta.touched && meta.error &&
                        <div className="form-error">{meta.error}</div>
                    }
                </div>
            </label>
        </>
    );
}

