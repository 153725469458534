import React from 'react';
import classNames from 'classnames';
import { useField } from 'formik';
import MaskedInput from "react-text-mask";
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import { FormControlLoader } from '../common';
import { moneyUtils } from '../../utils';
import { FormError } from '../controls';
import { FormFieldLabel } from './FormFieldLabel';

interface Props {
    label?: string;
    placeholder: string;
    className: string;
    markRequired: boolean;
    loading: boolean;
    disabled: boolean;
    name: string;
    defaultValue?: string | null;
    isValueChanged?: boolean;
}

export const CurrencyInputField: React.FC<Props> = (
    {
        label,
        className,
        markRequired,
        loading,
        disabled,
        name,
        placeholder,
        defaultValue,
        isValueChanged
    }) => {
    const [field, meta, { setValue }] = useField({ name });
    return (
        <>
            <label className="form-label">
                {!!label && <FormFieldLabel text={label} required={markRequired} />}
                <div className={classNames(
                    'form-control-wrapper',
                    { 'form-control-changed': isValueChanged },
                    { 'form-control-error': meta.touched && meta.error })}
                >
                    <MaskedInput
                        name={name}
                        value={String(field.value)}
                        onBlur={field.onBlur}
                        className={classNames('form-control', className)}
                        mask={createNumberMask({
                            prefix: "",
                            suffix: "",
                            allowDecimal: false,
                            includeThousandsSeparator: true,
                        })}
                        onChange={(e) => {
                            const { value } = e.target;
                            const newValue = value ? moneyUtils.parse(value) : defaultValue ? moneyUtils.parse(defaultValue) : ''
                            setValue(newValue, true)
                        }}
                        placeholder={placeholder}
                        disabled={disabled}
                    />
                    {
                        meta.touched && meta.error && <FormError message={meta.error} />
                    }
                    <FormControlLoader inProgress={loading} />
                </div>
            </label>
        </>
    )
};
