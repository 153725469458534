import React, { useEffect, useRef, useState } from 'react';
import { TFilter, TFilterType } from '../../types/filters/FilterState';
import { useDispatch } from 'react-redux';
import { createFilterActions } from '../../actions/filter.actions';
import { usePrevious } from '../../effects';
import cn from 'classnames';
import { useFilterNextLineHide } from '../../effects/useFilterNextLine';
import IconSVG from '../../styles/svg-icons';
import {
    FilterDateTimeGroup,
    FilterSelectGroup,
    FilterDateGroup,
    FilterRangeGroup,
    FilterBooleanGroup,
    FilterRadioGroup,
    FilterType,
} from '../../types/filters/FilterGroup';
import { FilterOption } from '../../types/filters/FilterOption';
import { UserConfigFilter } from '../../types/user-config/UserConfigFilter';
import { FiltersManagementPopup, TRenderCustomAlertOptions } from '../amrPipeline/aggregated/filters-management/FiltersManagementPopup';
import { FilterManagementForm, FilterSaveMode } from '../amrPipeline/aggregated/filters-management/types';
import { FilterBoolean } from '../common/filters/FilterBoolean';
import { FilterDateRange } from '../common/filters/FilterDateRange';
import { FilterDateTimeRange } from '../common/filters/FilterDateTimeRange/FilterDateTimeRange';
import { FilterRadio } from '../common/filters/FilterRadio';
import { FilterRange } from '../common/filters/FilterRange';
import { FilterSelect } from '../common/filters/FilterSelect';
import { FilterYearsDateRange } from '../common/filters/FilterYearsDateRange';
import { FilterButton } from './FilterButton';
import { FilterClear } from './FilterClear';
import { Filter } from '../common/filters/Filter';
import { SubscriptionFeature } from '../../types/billing/SubscriptionFeature';
import { ActionBlocker } from '../access/ActionBlocker';
import { AlertOption } from '../../types/email-preferences/EmailPreferences';

export interface FiltersProps {
    isSearching: boolean;
    allowCollapsing?: boolean;
    onApply: () => void;
    onReset: () => void;
    hiddenFilters: string[];
    onVisibilityChange?: (filterName: string) => void;
    filterType: TFilterType;
    withBlockedSearchAndFilter?: boolean;
    isUpdating?: boolean;
    filter: TFilter<TFilterType>;
    filters?: UserConfigFilter[];
    filterChanged: boolean;
    filterModified: boolean;
    visibleFilters: string[];
    selectedFilterReferenceName?: string;
    applyDisabled?: boolean;
    onFilterSave?: (data: FilterManagementForm) => void;
    onFilterUpdate?: (data: FilterManagementForm) => void;
    filterConfigShowEmailNotification?: boolean;
    saveFilterBlockerFeature?: SubscriptionFeature;
    saveFilterBlockerText?: React.ReactNode;
    saveFilterVisible?: boolean;
    advancedFiltersBlockerFeature?: SubscriptionFeature;
    advancedFiltersBlockerText?: React.ReactNode;
    alertOptions?: AlertOption[];
    defaultAlertOption?: AlertOption;
    alertOptionInfo?: { [key in AlertOption]?: string };
    renderCustomAlertOptions?: TRenderCustomAlertOptions;
}

interface CollapsibleFilterProps {
    filtersCollapseStatus: boolean;
    setFiltersCollapseStatus: (isCollapsed: boolean) => void;
}

export const Filters = ({
    isSearching,
    onApply,
    onReset,
    allowCollapsing = true,
    hiddenFilters = [],
    onVisibilityChange,
    filterType,
    filtersCollapseStatus,
    setFiltersCollapseStatus,
    withBlockedSearchAndFilter = false,
    isUpdating = false,
    filter,
    filters,
    selectedFilterReferenceName,
    visibleFilters,
    filterChanged,
    filterModified,
    applyDisabled,
    onFilterSave,
    onFilterUpdate,
    filterConfigShowEmailNotification,
    saveFilterBlockerFeature,
    saveFilterBlockerText,
    advancedFiltersBlockerFeature,
    advancedFiltersBlockerText,
    saveFilterVisible = true,
    alertOptions,
    defaultAlertOption,
    alertOptionInfo,
    renderCustomAlertOptions
}: FiltersProps & CollapsibleFilterProps) => {
    const isFilterCollapsed = filtersCollapseStatus;
    const filterActions = createFilterActions(filterType);

    const dispatch = useDispatch();

    const filterContainer = useRef<HTMLDivElement>(null);
    const filtersRef = useRef<HTMLDivElement[]>([]);

    const [isHideFilterButtonVisible, setIsHideFilterButtonVisible] = useState(false);
    const [showFiltersPopup, setShowFiltersPopup] = useState(false);
    const [lastAddedFilter, setLastAddedFilter] = useState<string>();

    const previousCollapsedState = usePrevious(isFilterCollapsed);

    const additionalFilters = hiddenFilters.map(key => filter[key]);

    const moreFilters = additionalFilters?.map(f => ({
        text: f.text,
        disabled: f.disabled,
        selected: f.selected,
        visible: true,
        value: f.key,
    }));

    const countOfHiddenFilters = useFilterNextLineHide(
        filterContainer,
        filtersRef,
        isFilterCollapsed,
        shouldShowHideButton => setIsHideFilterButtonVisible(shouldShowHideButton),
    );

    useEffect(() => {
        if (selectedFilterReferenceName) {
            setLastAddedFilter('');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedFilterReferenceName]);

    useEffect(() => {
        if (!isUpdating) {
            setShowFiltersPopup(false);
        }
    }, [isUpdating]);

    const handleFilterVisibilityChange = (filter: FilterOption, checked: boolean) => {
        setLastAddedFilter(checked ? (filter.value as string) : undefined);
        onVisibilityChange && onVisibilityChange(filter.value as string);
    };

    const handleFilterSelectOptionsChange = (filter: FilterOption, filterName: string) =>
        dispatch(filterActions.filterSelectChange(filter.value, filterName));

    const handleApplyFilter = () => {
        onApply();
    };

    const handleClearFilter = () => onReset();

    const handleSaveFilter = (data: FilterManagementForm) => {
        switch (data.mode) {
            case FilterSaveMode.Create:
                onFilterSave && onFilterSave(data);
                break;
            case FilterSaveMode.Update:
                onFilterUpdate && onFilterUpdate(data);
                break;
            default:
        }
        setShowFiltersPopup(false);
    };

    const renderFilter = (
        f:
            | FilterDateTimeGroup
            | FilterSelectGroup
            | FilterDateGroup
            | FilterRangeGroup
            | FilterBooleanGroup
            | FilterRadioGroup<any>,
        defaultExpanded: boolean = false,
    ) => {
        const { type, isApplied } = f;
        filtersRef.current = [];

        return (
            f.visible && (
                <Filter ref={(el: HTMLDivElement) => (filtersRef.current = [...filtersRef.current, el])} key={f.key}>
                    {type === FilterType.Select && (
                        <FilterSelect
                            isApplied={isApplied}
                            defaultExpanded={defaultExpanded}
                            className={f.text}
                            title={f.text}
                            withSearch={f.withSearch}
                            onClearAll={() => dispatch(filterActions.filterSelectClearAll(f.key))}
                            onSelectAll={searchTerm => dispatch(filterActions.filterSelectSelectAll(f.key, searchTerm))}
                            options={(f as FilterSelectGroup).filter}
                            onChangeItemSelection={v => handleFilterSelectOptionsChange(v, f.key)}
                            disabled={withBlockedSearchAndFilter}
                        />
                    )}
                    {type === FilterType.Range && (
                        <FilterRange
                            defaultExpanded={defaultExpanded}
                            isApplied={isApplied}
                            title={f.text}
                            options={(f as FilterRangeGroup).filter}
                            onClearAll={() => dispatch(filterActions.filterRangeClearSelectedOptions(f.key))}
                            onChangeRange={i => dispatch(filterActions.changeRangeFilter(i, f.key))}
                            mask={{
                                allowDecimal: true,
                                integerLimit: 6,
                                decimalLimit: (f as FilterRangeGroup).decimals ?? 2,
                            }}
                            disabled={withBlockedSearchAndFilter}
                        />
                    )}
                    {type === FilterType.Date && (
                        <ActionBlocker feature={f.requiredFeature}>
                            {blocked =>
                                <FilterDateRange
                                    isApplied={isApplied}
                                    defaultExpanded={defaultExpanded}
                                    acceptableOptions={(f as FilterDateGroup).acceptableOptions}
                                    title={f.text}
                                    onClearAll={() => dispatch(filterActions.filterDateClearSelectedOptions(f.key))}
                                    onSelectedDateChange={option =>
                                        dispatch(filterActions.filterDateSelectOption(option, f.key))
                                    }
                                    onCustomDateChange={option =>
                                        dispatch(filterActions.filterDateSelectCustomRange(option, f.key))
                                    }
                                    customDateRange={(f as FilterDateGroup).filter.options.customDateRange}
                                    selectedFilterOption={(f as FilterDateGroup).filter.selectedOption}
                                    disabled={blocked || withBlockedSearchAndFilter}
                                    disabledDays={(f as FilterDateGroup).disabledDays}
                                />
                            }
                        </ActionBlocker>
                    )}
                    {type === FilterType.DateWithYearsRange && (
                        <FilterDateRange
                            isApplied={isApplied}
                            defaultExpanded={defaultExpanded}
                            acceptableOptions={(f as FilterDateGroup).acceptableOptions}
                            title={f.text}
                            onClearAll={() => dispatch(filterActions.filterDateClearSelectedOptions(f.key))}
                            onSelectedDateChange={option =>
                                dispatch(filterActions.filterDateSelectOption(option, f.key))
                            }
                            onCustomDateChange={option =>
                                dispatch(filterActions.filterDateSelectCustomRange(option, f.key))
                            }
                            onYearsRangeChange={option =>
                                dispatch(filterActions.filterDateSelectYearsRange(option, f.key))
                            }
                            customDateRange={(f as FilterDateGroup).filter.options.customDateRange}
                            customYearsRange={(f as FilterDateGroup).filter.options.customYearsRange}
                            selectedFilterOption={(f as FilterDateGroup).filter.selectedOption}
                            disabled={withBlockedSearchAndFilter}
                        />
                    )}
                    {type === FilterType.DateWithYearsAndTimeRange && (
                        <FilterDateTimeRange
                            isApplied={isApplied}
                            defaultExpanded={defaultExpanded}
                            acceptableOptions={(f as FilterDateTimeGroup).acceptableOptions}
                            title={f.text}
                            onClearAll={() => dispatch(filterActions.filterDateClearSelectedOptions(f.key))}
                            selectedFilterOption={(f as FilterDateTimeGroup).filter.selectedOption}
                            onSelectedDateChange={option =>
                                dispatch(filterActions.filterDateTimeOptionSelected(option, f.key))
                            }
                            value={(f as FilterDateTimeGroup).filter.selectedOption}
                            onCustomDateChange={option =>
                                dispatch(filterActions.filterDateTimeSelectCustomRange(option, f.key))
                            }
                            disabled={withBlockedSearchAndFilter}
                        />
                    )}
                    {type === FilterType.YearsDate && (
                        <FilterYearsDateRange
                            isApplied={isApplied}
                            defaultExpanded={defaultExpanded}
                            acceptableOptions={(f as FilterDateGroup).acceptableOptions}
                            title={f.text}
                            onClearAll={() => dispatch(filterActions.filterDateClearSelectedOptions(f.key))}
                            onSelectedDateChange={option =>
                                dispatch(filterActions.filterDateSelectOption(option, f.key))
                            }
                            onCustomDateChange={option =>
                                dispatch(filterActions.filterDateSelectCustomRange(option, f.key))
                            }
                            value={(f as FilterDateGroup).filter.options.customDateRange}
                            selectedFilterOption={(f as FilterDateGroup).filter.selectedOption}
                        />
                    )}
                    {type === FilterType.Radio && (
                        <FilterRadio
                            isApplied={isApplied}
                            options={(f as FilterRadioGroup<typeof type>).filter.options}
                            defaultExpanded={defaultExpanded}
                            className={f.text}
                            title={f.text}
                            value={(f as FilterRadioGroup<typeof type>).filter.selectedOption}
                            onClearAll={() => dispatch(filterActions.filterRadioClearOption(f.key))}
                            onChangeItemSelection={value => dispatch(filterActions.filterRadioChange(value, f.key))}
                            disabled={withBlockedSearchAndFilter}
                        />
                    )}
                    {type === FilterType.BooleanRadio && (
                        <FilterBoolean
                            isApplied={isApplied}
                            defaultExpanded={defaultExpanded}
                            className={f.text}
                            title={f.text}
                            value={(f as FilterBooleanGroup).filter.selectedOption}
                            onClearAll={() => dispatch(filterActions.filterRadioClearOption(f.key))}
                            onChangeItemSelection={value => dispatch(filterActions.filterRadioChange(value, f.key))}
                            disabled={withBlockedSearchAndFilter}
                        />
                    )}
                </Filter>
            )
        );
    };

    const renderMoreFiltersSelect = () =>
        moreFilters &&
        moreFilters.length > 0 && (
            <Filter>
                <ActionBlocker tooltipContent={advancedFiltersBlockerText} feature={advancedFiltersBlockerFeature}>
                    {blocked => (
                        <FilterSelect
                            className="settings"
                            closeOnSelect
                            withSearch
                            disabled={blocked || withBlockedSearchAndFilter}
                            options={moreFilters.sort((a, b) => a.text.localeCompare(b.text))}
                            formatTitle={false}
                            withExpandToggle={false}
                            onChangeItemSelection={handleFilterVisibilityChange}
                            title={
                                <>
                                    <IconSVG name="plus-sm" width={12} height={12} />
                                    <span>Filters</span>
                                </>
                            }
                        />
                    )}
                </ActionBlocker>
            </Filter>
        );

    const renderDefaultFilters = () => {
        return (
            <>
                {(visibleFilters as any[])
                    .filter(f => !additionalFilters.some(filter => filter.key === f))
                    .map(visibleFilter => {
                        const f = filter[visibleFilter];
                        return renderFilter(f);
                    })}
            </>
        );
    };

    const renderAdditionalFilters = () => {
        return (
            <>
                {(visibleFilters as any[])
                    .filter(f => additionalFilters.some(filter => filter.key === f))
                    .map(visibleFilter =>
                        renderFilter(
                            filter[visibleFilter],
                            lastAddedFilter === visibleFilter && !previousCollapsedState && !isFilterCollapsed,
                        ),
                    )}
            </>
        );
    };

    const renderButtons = () => (
        <>
            <div className="filter-item filter-item-control">
                <FilterButton caption="Apply" disabled={applyDisabled} onClick={handleApplyFilter} />
                <FilterClear isShown={filterChanged} isDisabled={isSearching} onClick={handleClearFilter} />
                {filterModified && saveFilterVisible && (
                    <>
                        <span className="vertical-divider"></span>
                        <ActionBlocker tooltipContent={saveFilterBlockerText} feature={saveFilterBlockerFeature}>
                            <FilterButton
                                className="btn-link secondary regular"
                                onClick={() => setShowFiltersPopup(true)}
                            >
                                Save this filter
                            </FilterButton>
                        </ActionBlocker>
                    </>
                )}
                {filters && showFiltersPopup && (
                    <FiltersManagementPopup
                        showEmailNotificationsOptions={filterConfigShowEmailNotification}
                        filters={filters}
                        selectedReferenceName={selectedFilterReferenceName}
                        isUpdating={isUpdating}
                        alertOptions={alertOptions}
                        defaultAlertOption={defaultAlertOption}
                        alertOptionInfo={alertOptionInfo}
                        renderCustomAlertOptions={renderCustomAlertOptions}
                        onSave={handleSaveFilter}
                        onClose={() => setShowFiltersPopup(false)}
                    />
                )}
            </div>
        </>
    );

    return (
        <div
            ref={filterContainer}
            className={cn('flex-wrap row-filters', { 'flex-item': !isFilterCollapsed, collapsed: isFilterCollapsed })}
        >
            {isFilterCollapsed && (
                <>
                    {allowCollapsing && (
                        <>
                            <div className="filter-item">
                                <button
                                    onClick={() => setFiltersCollapseStatus(false)}
                                    className="btn-link secondary regular"
                                >
                                    Show Filters
                                </button>
                            </div>
                            <span className="vertical-divider" />
                        </>
                    )}
                    {renderDefaultFilters()}
                    {renderAdditionalFilters()}
                </>
            )}
            {!isFilterCollapsed && (
                <>
                    {allowCollapsing && (
                        <>
                            <div className="filter-item">
                                <button
                                    disabled={!isHideFilterButtonVisible}
                                    onClick={() => setFiltersCollapseStatus(true)}
                                    className="btn-link secondary regular"
                                >
                                    Hide Filters
                                </button>
                            </div>
                            <span className="vertical-divider" />
                        </>
                    )}
                    {renderDefaultFilters()}
                    {renderAdditionalFilters()}
                </>
            )}
            <div className={cn('row-filters-btns', { collapsed: isFilterCollapsed })}>
                {!isFilterCollapsed && renderMoreFiltersSelect()}
                {isFilterCollapsed && (
                    <button
                        onClick={() => setFiltersCollapseStatus(false)}
                        className="control-filter-select-btn hidden-filters-box"
                    >
                        + {countOfHiddenFilters} filters
                    </button>
                )}
                <span className="vertical-divider" />
                {renderButtons()}
            </div>
        </div>
    );
};
