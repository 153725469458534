import { FormError, MultiSelect, MultiSelectDataItem } from "../controls"
import cn from 'classnames';
import classNames from "classnames";


interface Props {
    dataItems: MultiSelectDataItem[];
    selectedValues: Array<string | number>;
    markRequired?: boolean;
    label: string;
    errorMessage: string;
    name: string;
    placeholder: string;
    onSelect: (item: MultiSelectDataItem, selected: boolean) => void;
    onResetAll?: (e: React.MouseEvent) => void;
    onSelectAll?: (e: React.MouseEvent) => void;
    required?: boolean;
    horizontalOrientation?: boolean;
    generateSelectedText?: (selectedItems: MultiSelectDataItem[]) => React.ReactNode;
    resetButtonText?: string;
}

export default function MultiSelectField({
    onSelect,
    dataItems,
    selectedValues,
    markRequired = true,
    label,
    errorMessage,
    name,
    placeholder,
    onResetAll,
    onSelectAll,
    horizontalOrientation,
    generateSelectedText,
    resetButtonText = 'Reset'
}: Props) {

    return (
        <div className={classNames("position-relative", { "data-item-row": horizontalOrientation })}>
            <label className={classNames({
                "data-item-col data-item-col-title data-item-capitalize": horizontalOrientation,
                "form-label": !horizontalOrientation
            })}>
                {label} {markRequired && <span className="text-red">*</span>}
            </label>
            <div className={classNames({ "data-item-col": horizontalOrientation })}>
                <MultiSelect
                    showResetButton={!!onResetAll}
                    showSelectAllButton={!!onSelectAll}
                    className={cn({ 'is-invalid': !!errorMessage })}
                    name={name}
                    required={true}
                    sourceItems={dataItems.map((s: MultiSelectDataItem) => ({
                        selected: selectedValues.some((i) => i === s.value),
                        value: s.value,
                        text: s.text,
                        disabled: s.disabled
                    }))}
                    placeholder={placeholder}
                    onItemSelect={onSelect}
                    showAddButton={false}
                    onResetAll={onResetAll}
                    onSelectAll={onSelectAll}
                    generateSelectedText={generateSelectedText}
                    resetButtonText={resetButtonText}
                />
                <FormError message={errorMessage} />
            </div>
        </div>
    )
}
