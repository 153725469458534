import React from 'react';
import IconSVG from '../../styles/svg-icons';

export function FilterClear({ isShown = false, isDisabled = false, onClick }) {
    if (!isShown) {
        return null;
    }

    return (
        <button
            type="button"
            className="btn-link btn-clear"
            onClick={() => onClick && onClick()}
            disabled={isDisabled}
        >
            <IconSVG name="close" width={16} height={16} />
            Reset
        </button>
    )
}
