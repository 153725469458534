import React, { Component } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import TextareaAutosize from 'react-textarea-autosize';
import { cellEvents } from './cell.events';

class Textarea extends Component {
    shouldComponentUpdate = nextProps =>
        !!this.props.hasFocus !== !!nextProps.hasFocus
        || !!this.props.editing !== !!nextProps.editing
        || this.props.value !== nextProps.value
        || this.props.error !== nextProps.error;

    render = () => {
        const {
            column,
            value,
            hasFocus,
            editing,
            error,
            showPlaceholder,
            placeholder,
            readonly
        } = this.props;

        const className = classNames({
            [`cell-${column.name}`]: true,
            'has-focus': hasFocus,
            readonly
        });

        return (
            <td
                className={className}
                onClick={cellEvents.handleClick.bind(this, column.name)}
                onCopy={cellEvents.handleCopy.bind(this)}
                onPaste={editing ? undefined : cellEvents.handlePaste.bind(this)}
            >
                <div className={classNames({ 'cell-item': true, error: !!error })}>
                    {
                        editing
                            ? <TextareaAutosize
                                minRows={1}
                                maxRows={4}
                                autoFocus
                                maxLength={column.length || 512}
                                className="cell-item-control"
                                onChange={cellEvents.handleChange.bind(this)}
                                onFocus={e => e.target.select()}
                                value={value || ''}
                            />
                            : <span className="cell-item-text">{value || (showPlaceholder && <span>{placeholder}</span>)}</span>
                    }
                    {hasFocus && !editing && <input autoFocus type="text" className="invisible" />}
                    {error && hasFocus && <div className="form-error">{error}</div>}
                </div>
            </td>
        );
    }
}

const connectedTextarea = connect()(Textarea);
export { connectedTextarea as Textarea };
