import React, { forwardRef } from 'react';
import classNames from 'classnames';
import IconSVG from '../../styles/svg-icons';

export const FilterChip = forwardRef(
    ({ text = '', selected = false, refParent, onRemove = () => false }, ref) => (
        <div ref={ref} className="chip">
            <div className={classNames('chip-content', { selected })}>
                <div className="chip-text">{text}</div>
                <button type="button" className="chip-button" onClick={onRemove}>
                    <IconSVG name="close" width={16} height={16} />
                </button>
            </div>
        </div>
    )
);
