import * as React from 'react';

interface Props {
    text: React.ReactNode
    required?: boolean
}
export function FormFieldLabel({ text, required = false }: Props) {
    return (
        <label className="form-label">{text} {required && <span className="text-red">*</span>}</label>
    );
}
