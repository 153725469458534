import React, { useEffect, useRef, useState } from 'react';
import cn from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { searchSecuritiesActions } from '../../actions';
import { ClickOutside } from '../common/ClickOutside';
import { ChipList } from './ChipList';
import IconSVG from '../../styles/svg-icons';
import { useLocation } from 'react-router';
import { ActionBlocker } from '../access/ActionBlocker';


export function SecuritiesSearchInput(
    {
        singleSearch = false,
        disabled = false,
        placeholder = '',
        className = '',
        defaultSearchTerm = '',
        maxLength,
        compactView = false,
        requiredFeature,
        onSearch
    }) {
    const location = useLocation();
    const searchInputId = 'search-input-id';
    const refInput = useRef(null);
    const refScrollableContainer = useRef(null);
    const refActiveItem = useRef(null);
    const dispatch = useDispatch();
    const [index, setIndex] = useState(0);
    const [inputFocus, setInputFocus] = useState(false);
    const [expanded, setExpanded] = useState(!compactView);

    const searchTerm = useSelector(state => state.searchSecurities.searchTerm) || '';
    const searchTermItemsState = useSelector(state => state.searchSecurities.searchTermItems);
    const searchTermItems = searchTermItemsState || [];
    const markerPosition = useSelector(state => state.searchSecurities.markerPosition);
    const lookupItems = useSelector(state => state.searchSecurities.lookup);
    const lookup = lookupItems || [];

    useEffect(() => () => {
        dispatch(searchSecuritiesActions.reset());
    }, [location.pathname, dispatch])

    useEffect(() => {
        if (defaultSearchTerm) {
            dispatch(searchSecuritiesActions.addSearchItem(defaultSearchTerm));
        }
    }, [defaultSearchTerm, dispatch]);

    useEffect(() => {
        if (refScrollableContainer.current && refActiveItem.current && lookupItems && lookupItems.length) {
            const container = refScrollableContainer.current.getBoundingClientRect();
            const item = refActiveItem.current.getBoundingClientRect();
            if (item.top < container.top || item.bottom > container.bottom) {
                refActiveItem.current.scrollIntoView(false);
            }
        }
    }, [lookupItems, index]);

    useEffect(() => {
        if (refInput.current && !disabled && inputFocus) {
            refInput.current.focus();
        }
    }, [disabled, inputFocus]);

    useEffect(() => {
        if (compactView && !expanded && searchTermItemsState && searchTermItemsState.length) {
            setExpanded(true);
        }
    }, [compactView, expanded, searchTermItemsState]);

    const getPlaceholder = () => {
        if (searchTermItems.length) {
            return '';
        }
        return placeholder ? placeholder : 'Search by ISIN/CUSIP or ticker';
    };

    const handleSearchTermChange = e => {
        if (disabled) {
            return;
        }
        if (singleSearch && searchTermItems[0]) {
            handleClearAll();
        }
        setIndex(0);
        dispatch(searchSecuritiesActions.searchTermChange(e.target.value));
    };

    const handleClearAll = () => {
        setIndex(0);
        dispatch(searchSecuritiesActions.reset());
        refInput.current && refInput.current.focus();
        onSearch && onSearch();
        setInputFocus(true);
    };

    const handleLookupItemClick = lookupItem => {
        setIndex(0);
        refInput.current && refInput.current.focus();
        dispatch(searchSecuritiesActions.applyLookupItem(lookupItem));
        onSearch && onSearch();
        setInputFocus(true);
    };

    const handleRemoveChip = i => {
        dispatch(searchSecuritiesActions.removeSearchItem(i));
        refInput.current && refInput.current.focus();
        onSearch && onSearch();
        setInputFocus(true);
    };

    const handleClickOutside = e => {
        e.stopPropagation();
        if (lookup.length && refInput.current && e.target.id !== searchInputId) {
            setIndex(0);
            dispatch(searchSecuritiesActions.resetSearchLookup());
            dispatch(searchSecuritiesActions.searchTermChange(''));
            setInputFocus(false);
        }
    };

    const handleKeyDown = e => {
        if (e.keyCode === 8) { // backspace
            if (markerPosition > 0 && !searchTerm) {
                dispatch(searchSecuritiesActions.removeCurrentItem());
                onSearch && onSearch();
            } else if (e.currentTarget.selectionStart === 0 && !searchTerm) {
                handleRemoveChip(searchTermItems.length - 1);
            }
        } else if (!searchTerm && e.keyCode === 37 && e.currentTarget.selectionStart === 0) { // left arrow
            dispatch(searchSecuritiesActions.moveBack());
        } else if (!searchTerm && e.keyCode === 39 && markerPosition !== -1) { // right arrow
            e.preventDefault();
            dispatch(searchSecuritiesActions.moveForward());
        }
        if (lookup.length) {
            if (e.keyCode === 13) { // enter
                handleLookupItemClick(lookup[index]);
            } else if (e.keyCode === 38) { // move up
                e.preventDefault();
                if (index > 0) {
                    setIndex(index - 1);
                }
            } else if (e.keyCode === 40 && index < lookup.length - 1) { // move down
                setIndex(index + 1);
            }
        }
    };

    const handleSearchClick = () => {
        setExpanded(!expanded);
    }

    const renderLookupItem = (item, i) =>
        <tr
            key={i}
            ref={node => refActiveItem.current = (index === i ? node : refActiveItem.current)}
            className={cn({ active: index === i })}
            onClick={() => handleLookupItemClick(item)}
        >
            {
                i === 0
                    ?
                    <>
                        <td className="col-main"><strong>Custom search:</strong></td>
                        <td className="col-ellipsis">{item}</td>
                    </>
                    :
                    <>
                        <td className="col-main">{item.ticker}</td>
                        <td>{item.isinCusip}</td>
                    </>
            }
        </tr>;

    return (
        <ActionBlocker feature={requiredFeature}>
            {blocked =>
                <div
                    className={cn('control-search-wrapper', className, {
                        'control-search-focus': inputFocus,
                        'compact': compactView,
                        'compact-expanded': expanded,
                        disabled: disabled || blocked
                    })}
                    onBlur={() => setInputFocus(false)}
                >
                    <div className="form-control-search-btn" onClick={handleSearchClick}>
                        <i className="icon icon-search" />
                    </div>
                    {
                        expanded &&
                        <>
                            {
                                !singleSearch &&
                                <ChipList
                                    list={searchTermItems}
                                    selectionIndex={markerPosition}
                                    onRemove={handleRemoveChip}
                                />
                            }
                            <input
                                ref={refInput}
                                className="form-control form-control-search"
                                type="text"
                                id={searchInputId}
                                value={singleSearch ? (searchTermItems[0] || searchTerm) : searchTerm}
                                placeholder={getPlaceholder()}
                                maxLength={maxLength}
                                disabled={disabled || blocked}
                                onChange={handleSearchTermChange}
                                onKeyDown={handleKeyDown}
                                onFocus={() => setInputFocus(true)}
                            />
                            {
                                (!!searchTerm || !!searchTermItems.length) &&
                                <button className="btn-close" disabled={disabled || blocked} onClick={handleClearAll}>
                                    <IconSVG name="close" width={16} height={16} />
                                </button>
                            }
                            {
                                !!lookup.length && !!lookup[0].trim() && !!searchTerm &&
                                <ClickOutside onClick={handleClickOutside}>
                                    <div ref={refScrollableContainer} className="search-lookup">
                                        <table>
                                            <tbody>
                                                {lookup.map((item, i) => renderLookupItem(item, i))}
                                            </tbody>
                                        </table>
                                    </div>
                                </ClickOutside>
                            }
                        </>
                    }
                </div>}
        </ActionBlocker>
    );
}

SecuritiesSearchInput.defaultProps = {
    maxLength: null,
    onSearch: undefined,
    requiredFeature: null
};
