import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import cn from 'classnames';
import { Header } from './Header';
import { Row } from './Row';
import { gridActions as actions } from '../../actions';
import { constants } from '../../constants';
import { Preloader } from '../common';
import IconSVG from '../../styles/svg-icons';
import { SelectFile } from './SelectFile';
import { DragFileZone } from '../common/DragFileZone';
import { AppState } from '../../types/state/AppState';
import { GridDataItem, GridUploadStatus } from '../../types/state/GridState';
import { useAppDispatch } from '../../effects/useAppDispatch';

interface Props {
    dataUploadDisabled?: boolean;
    onDataItemsPaste?: (clipboardText: string) => void;
    onDataItemsUpload?: (file: File) => void;
    deleteDisabled?: boolean;
    markOptionalFields?: boolean;
    onRowDeleted?: () => void;
    readonlyTooltip?: string;
    helpPopoverTitle?: string;
    renderFooter?: (dataItem: GridDataItem<any>) => React.ReactNode;
    addRowVisible?: boolean;
    moveRowVisible?: boolean;
    deleteRowVisible?: boolean;
    onFileSelected?: (file: File) => void;
    dataItems?: GridDataItem<any>[];
}

export const Grid = (
    {
        dataUploadDisabled = false,
        onDataItemsPaste,
        onDataItemsUpload,
        deleteDisabled = false,
        markOptionalFields = false,
        onRowDeleted = () => false,
        readonlyTooltip,
        helpPopoverTitle = '',
        renderFooter,
        addRowVisible = true,
        moveRowVisible = true,
        deleteRowVisible = true,
        onFileSelected = () => false,
        ...rest
    }: Props) => {
    const dispatch = useAppDispatch();

    const gridRef = useRef<HTMLTableElement>(null);
    const [dragEnter, setDragEnter] = useState(false);

    const blockInput = useSelector((state: AppState) => state.grid.inputBlocked)
    const storeDataItems = useSelector((state: AppState) => state.grid.dataItems)
    const dataItems = rest.dataItems || storeDataItems || []
    const upload = useSelector((state: AppState) => state.grid.upload || {})
    const isDataProcessing = useSelector((state: AppState) => state.grid.isDataProcessing)
    const rowsLimit = useSelector((state: AppState) => state.grid.rowsLimit)

    const isNestedTarget = (target: EventTarget | null ) => gridRef.current && target && gridRef.current.contains(target as Node);
    const isDatePickerTarget = (target: EventTarget | null) => {
        if (!target) {
            return false;
        }

        const tooltip = document.querySelector('.grid-date-cell-tooltip');

        return tooltip && tooltip.contains(target as Node);
    }

    const handleOnPaste = useCallback((e: ClipboardEvent) => {
        if (blockInput || !isNestedTarget(e && e.target)) {
            return;
        }
        //@ts-ignore
        const clipboardData = e.clipboardData || window.clipboardData;
        const clipboardText = clipboardData.getData('text')?.replace(/\r\n$/, '');

        if (clipboardText.indexOf("\t") > -1 || clipboardText.indexOf("\n") > -1) {
            dispatch(actions.cancelEdit());
            onDataItemsPaste && onDataItemsPaste(clipboardText);
        }// eslint-disable-next-line
    }, [blockInput, dispatch])

    const handleKeyDown = useCallback(async (e: KeyboardEvent) => {
        if (blockInput || !isNestedTarget(e.target)) return;

        dispatch(actions.processKeyCode(e.keyCode, e.ctrlKey, e.metaKey, e.shiftKey, deleteDisabled));

        if (e.keyCode === 9) e.preventDefault();
    }, [blockInput, dispatch, deleteDisabled])

    const handleClickOutside = useCallback((e: MouseEvent) => {
        if (!isNestedTarget(e.target) && !(isDatePickerTarget(e.target))) {
            dispatch(actions.applyOrCancelEdit());
        }
    }, [dispatch])

    useEffect(() => {
        if (!dataUploadDisabled) {
            document.addEventListener('paste', handleOnPaste);
        }
        document.addEventListener('keydown', handleKeyDown);
        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('paste', handleOnPaste);
            document.removeEventListener('keydown', handleKeyDown);
            document.removeEventListener('mousedown', handleClickOutside);
        }
    }, [dataUploadDisabled, handleOnPaste, handleKeyDown, handleClickOutside]);

    const handleOnFile = (file: File) => {
        setDragEnter(false);
        if (!blockInput && file && onDataItemsUpload) onDataItemsUpload(file);
    }

    const rows = useMemo(() => dataItems.map((item: GridDataItem<any>[], index: number) =>
        <Row
            key={index}
            index={index}
            dataItem={item}
            onRowDeleted={onRowDeleted}
            readonlyTooltip={readonlyTooltip}
            helpPopoverTitle={helpPopoverTitle}
            deleteDisabled={deleteDisabled}
            deleteRowVisible={deleteRowVisible}
            addRowVisible={addRowVisible}
            moveRowVisible={moveRowVisible} // eslint-disable-next-line
        />), [dataItems, readonlyTooltip, helpPopoverTitle, deleteDisabled, addRowVisible, moveRowVisible]);

    if (!dataItems) {
        return null;
    }

    const highlightClasses = cn({
        'block-prompt-upload': true,
        'highlight': upload.status === GridUploadStatus.Uploading
    });

    const renderUploadingMessage = () => {
        if (dragEnter) return 'Your records will now be added to this table'
        if (upload.status === GridUploadStatus.None) {
            return <>Drag & Drop file here or <SelectFile onClick={onFileSelected} /></>
        }
        if (upload.status === GridUploadStatus.Uploading) {
            return (
                <span className="uploading-progress">
                   <span className="uploading-progress-name text-ellipsis">
                       Uploading "{upload.filename}"
                   </span>
                </span>
            )
        }
    }


    return (
        <DragFileZone
            className={cn('grid-drop-section container-flex', { 'highlight': dragEnter })}
            onDragEnter={dataUploadDisabled ? undefined : () => setDragEnter(true)}
            onDragLeave={dataUploadDisabled ? undefined : () => setDragEnter(false)}
            onFile={dataUploadDisabled ? undefined : handleOnFile}
        >
            <div className="grid-table-wrapper container-flex">
                <table ref={gridRef} id="grid" tabIndex={-1} className="grid container-flex">
                    <Header markOptionalFields={markOptionalFields} addRowVisible={addRowVisible} />
                    <tbody>{rows}</tbody>
                    {typeof renderFooter === 'function' && <tfoot>{renderFooter(dataItems)}</tfoot>}
                </table>
            </div>
            {
                !dataUploadDisabled &&
                <>
                    <Preloader inProgress={upload.status === GridUploadStatus.Uploading || isDataProcessing} />
                    <div className="block-prompt-wrapper">
                        <div className={highlightClasses}>
                            <div className="content-block-prompt">
                                <p className="title">
                                    <IconSVG name="upload-doc" width={24} height={24} />
                                    {renderUploadingMessage()}
                                </p>
                                {
                                    upload.status === GridUploadStatus.None && !dragEnter &&
                                    <span>
                                        Format: xlsx, csv (max 10MB or {rowsLimit || constants.gridRowsLimit} records)
                                    </span>
                                }
                            </div>
                        </div>
                    </div>
                </>
            }
        </DragFileZone>
    );
}
