import React, { Component } from 'react';
import { connect } from 'react-redux';
import { classnames } from '../../../styles/classnames';
import { gridActions as actions } from '../../../actions';
import { constants } from '../../../constants';

class Select extends Component {
    shouldComponentUpdate = nextProps =>
        !!this.props.hasFocus !== !!nextProps.hasFocus
        || !!this.props.editing !== !!nextProps.editing
        || this.props.value !== nextProps.value
        || this.props.source !== nextProps.source
        || this.props.error !== nextProps.error
        || this.props.readonly !== nextProps.readonly;

    render = () => {
        const { hasFocus, columnName, error, readonly } = this.props;

        const cssClasses = classnames({
            ['cell-' + columnName]: true,
            'has-focus': hasFocus,
            readonly
        });

        return (
            <td className={cssClasses} onClick={this.handleClick}>
                <div className={classnames({ 'cell-item': true, error: !!error })}>
                    {hasFocus && !readonly ? this.renderSelect() : this.renderValue()}
                    {error && hasFocus && <div className="form-error">{error}</div>}
                </div>
            </td>
        );
    }

    renderValue = () => {
        const { format, value, source = [] } = this.props;
        const item = source.find(s => s.key !== undefined  ? String(s.key) === String(value) : s === value);
        const itemValue = item ? item.title ?? item : value;

        return (
            <span className="cell-item-text">
                {typeof format === 'function' ? format(itemValue) : itemValue}
            </span>
        );
    }

    renderSelect = () => {
        const { value, error, keepEmptyOption, source = [] } = this.props;

        const options = source.map(s => <option disabled={s.disabled} key={s?.key ?? s} value={s.key ?? s}>{s?.title ?? s}</option>);

        if (
            (typeof value === 'undefined' || value === null || value === '' || error || keepEmptyOption)
        ) {
            options.unshift(
                <option key={-1} value={null}>
                    {constants.emptyPlaceholder}
                </option>,
            );
        }

        return (
            <select
                autoFocus
                onChange={this.handleChange}
                value={value ?? ''}
                className="form-control form-select cell-item-control"
                onKeyDown={this.handleSelectKeyDown}
            >
                {options}
            </select>
        );
    }

    handleClick = () => {
        const { hasFocus, onClick, columnName } = this.props;
        if (!hasFocus) {
            onClick(columnName, false);
        }
    }

    handleChange = e => {
        const { dispatch, source, editing, onClick, onChange, columnName } = this.props;

        const targetValue = e.target.value === constants.emptyPlaceholder ? undefined : e.target.value;

        if (!editing) {
            onClick(columnName, true);
        }

        const value = source.find(s => (s?.key ?? s)?.toString() === targetValue)?.key ?? targetValue

        if (onChange) {
            onChange(value);
        } else {
            dispatch(actions.editing(value));
        }

        dispatch(actions.applyEdit());
    }

    handleSelectKeyDown = event => {
        // block page up, page down, bottom arrow keys
        if (event.keyCode === 33 || event.keyCode === 34 || event.keyCode === 40) {
            event.preventDefault();
        }
    }
}

const connectedSelect = connect()(Select);
export { connectedSelect as Select };


