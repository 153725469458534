import React, { useRef } from 'react';
import classNames from 'classnames';
import { useField } from 'formik';
import { useFocusOnError } from '../../effects/useFocusOnError';

export function TextAreaField({ ...props }) {
    const fieldRef = useRef(null);
    useFocusOnError({ fieldRef, name: props.name });
    const [field, meta] = useField(props);
    return (
        <>
            <div className="form-control-wrapper">
                <textarea
                    ref={fieldRef}
                    className={classNames('form-control', { 'is-invalid': meta.touched && meta.error })}
                    {...field}
                    value={field.value || ''}
                    {...props}
                />
                {
                    meta.touched && meta.error &&
                    <div className="form-error">{meta.error}</div>
                }
            </div>
        </>
    )
}
