import { useEffect } from 'react';
import { useTrustee } from '../../../effects/data-accessors/useTrustee';
import { FilterOption } from '../../../types/filters/FilterOption';
import { isRequestFailed, isRequestSuccess } from '../../../utils';
import { useDispatch } from 'react-redux';
import { BwicFilterType } from '../../../types/filters/FilterState';
import { createFilterActions } from '../../../actions/filter.actions';

export const useTrusteeFilterData = (filterType: BwicFilterType, filterKey: string = 'trustees') => {
    const dispatch = useDispatch();
    const filterActions = createFilterActions(filterType);
    const { requestState: trusteeRequestState, items } = useTrustee();

    useEffect(() => {
        if (isRequestSuccess(trusteeRequestState) || isRequestFailed(trusteeRequestState)) {

            const trusteeOptions: FilterOption[] = [...items]
                .sort((a, b) => a.legalName.localeCompare(b.legalName))
                .map(t => ({
                    value: t.referenceName,
                    text: t.legalName,
                    selected: false,
                    visible: true,
                    disabled: false,
                }));

            dispatch(filterActions.storeFilterData.success({
                filterType: filterType,
                filterName: filterKey,
                data: trusteeOptions
            }))
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterType, filterKey, trusteeRequestState, dispatch])

    return trusteeRequestState;
}
